<template>
  <vue-good-table
    :columns="invoiceColumns"
    :rows="data"
    :use-dynamic-row-height="true"
    :sort-options="{
      enabled: false,
    }"
    :group-options="{
      enabled: true
    }"
    :pagination-options="{
      enabled: !this.$route.meta.print,
      perPageDropdown: [30, 40, 50, 100, 200],
      dropdownAllowAll: false,
    }"
    :search-options="{
        enabled: true,
    }" 
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'issue_date'">
        {{ props.row.issue_date.toDate() | moment('HH:mm') }}
      </span>
      <span v-else-if="props.column.field == 'visit_date'">
        {{ props.row.visit_date.toDate() | moment('DD/MM/YYYY HH:mm') }}
      </span>
      <span v-else-if="props.column.field == 'invoice_id'">
        <a href="#" @click="showInvoice(props.row.invoice_id)">{{ props.row.invoice_id }}</a>
      </span>
      <span v-else-if="props.column.field == 'patient_name'">
        <a target="_blank" :href="`/patient/${props.row.HN}`">{{props.row.patient_name}}</a>
      </span>
      <span v-else-if="props.column.field == 'payment_type'">
        <span v-for="(payment, index) of props.row.payments" :key="`type_${payment.payment_id}`">
          <a href="#" @click="showPayment(payment.payment_id)" >{{ index + 1 }}.{{ displayPaymentType(payment) }} </a>
          <br />
        </span>
      </span>
      <span v-else-if="props.column.field == 'payment_amount'">
        <span v-for="(payment, index) of props.row.payments" :key="`amount_${payment.payment_id}`">
          <a href="#" @click="showPayment(payment.payment_id)" >{{ index + 1 }}. {{ Math.round((Number(payment.cash)+Number(payment.credit)+Number(payment.internet_banking)+Number(payment.other)+Number(payment.insurance))).toLocaleString() }} </a>
          <br />
        </span>
      </span>
      <div v-else-if="props.column.field == 'discount'">
        {{ props.row.discount.toLocaleString() }}
      </div>
      <div v-else-if="props.column.field === 'print'">
        <button class="btn btn-light text-white" >
          <a target="_blank" :href="`/print/invoice/${props.formattedRow['invoice_id']}`"><i class="fas fa-print"></i></a>
        </button>
      </div>
      <div v-else-if="props.column.label == 'Total Amount'">
        {{ Math.round(props.formattedRow[props.column.field]).toLocaleString() }}
      </div>
      <div v-else-if="props.column.field === 'status'" class="text-center">
        <span class="badge" :class="{
          'badge-success': props.row['status'] === 'paid',
          'badge-warning': props.row['status'] === 'outstanding' || props.row['status'] === 'gop',
          'badge-danger': props.row['status'] === 'waiting for payment' || props.row['status'] === 'denied' || props.row['status'] === 'unpaid',
          'badge-dark': props.row['status'] === 'cancelled',
        }">
          {{ props.row['status'].toUpperCase() }}
        </span>
      </div>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      invoiceColumns: [
        {
          label: 'Date Time',
          field: 'issue_date',
        },
        {
          label: 'Visit Date',
          field: 'visit_date',
        },
        {
          label: 'Invoice#',
          field: 'invoice_id',
          width: '250px'
        },
        {
          label: 'Patient Name',
          field: 'patient_name',
          width: '200px'
        },
        {
          label: 'Payment Type',
          field: 'payment_type',
        },
        {
          label: 'Payment Amount',
          field: 'payment_amount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'Discount',
          field: 'discount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: '% Extra Charge',
          field: 'extra_charge',
          hidden: true
        },
        {
          label: 'Total Amount',
          field: 'total_invoiced',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'Outstanding',
          field: this.getOutstanding,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Print',
          field: 'print',
          hidden: !!this.$route.meta.print
        }
      ]
    }
  },
  methods: {
    getOutstanding(invoice) {
      let paidAmount = 0
      for (let payment of invoice.payments) {
        paidAmount += payment.total_payment
      }
      return (Number(invoice.total_invoiced) - Number(paidAmount)).toLocaleString()
    },
    displayPaymentType(payment) {
      let paymentType = ''

      if (payment.cash) paymentType += ' Cash'
      if (payment.credit) paymentType += ' Credit'
      if (payment.insurance) paymentType += ' Insurance'
      if (payment.internet_banking) paymentType += ' Internet banking'
      if (payment.other) paymentType += ' Other'

      return paymentType
    },
    showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    },
    showNewPayment(invoice) {
      this.$emit('displayNewPaymentModal', invoice)
    },
    showInvoice(invoiceId) {
      this.$emit('showInvoice', invoiceId)
    }
  }
}
</script>