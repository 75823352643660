<template>
  <div class="container-fluid" id="element-to-convert">
    <div class="row">
      <h3 class="text-primary">Report</h3>
    </div>

    <div class="row mt-2">
      <MazPicker
        v-model="filterDate"
        no-time
        format="DD/MM/YYYY"
        formatted="DD/MM/YYYY"
        placeholder="Date Range"
        range
      />
      <div class="text-center d-flex mx-3">
        <button
          class="btn btn-primary mx-auto ml-2 px-4"
          @click.prevent="search"
        >
          <i class="fas fa-search"></i> Search
        </button>
      </div>
      <div class="text-center d-flex mx-3">
        <button
          class="btn btn-primary mx-auto ml-2 px-4"
          @click.prevent="exportCsv(services,'Service')"
        >
          <i class="fas fa-file-excel"></i> Export Service
        </button>
      </div>
      <div class="text-center d-flex mx-3">
        <button
          class="btn btn-primary mx-auto ml-2 px-4"
          @click.prevent="exportCsv(products,'Product')"
        >
          <i class="fas fa-file-excel"></i> Export Product
        </button>
      </div>

      <div class="text-center d-flex mx-3">
        <button class="btn btn-primary mx-auto ml-2 px-4" @click.prevent="printReport">
          <i class="fas fa-file-pdf"></i> Print
        </button>
      </div>
    </div>

    <div class="row mt-4 d-flex flex-column">
      <h4 class="text-primary">Summary Of Revenue</h4>
      <vue-good-table
        :columns="summaryColumns"
        :rows="[...serviceSummary, ...productSummary, ...totalSummary]"
        :use-dynamic-row-height="true"
        styleClass="table condensed table-bordered vgt-table-report-print"
        :sort-options="{
          enabled: false,
        }"
        :group-options="{
          enabled: false,
        }"
        :pagination-options="{
          dropdownAllowAll: false,
        }"
      />
    </div>

    <div class="row mt-4 d-flex flex-column">
      <h4 class="text-primary">Service Revenue</h4>
      <vue-good-table
        :columns="serviceColumns"
        :rows="[...services, ...serviceSummary]"
        :use-dynamic-row-height="true"
        styleClass="table condensed table-bordered vgt-table-report-print"
        :sort-options="{
          enabled: false,
        }"
        :group-options="{
          enabled: false,
        }"
        :pagination-options="{
          dropdownAllowAll: false,
        }"
      />
    </div>

    <div class="row mt-4 d-flex flex-column">
      <h4 class="text-primary">Product Revenue</h4>
      <vue-good-table
        :columns="serviceColumns"
        :rows="[...products, ...productSummary]"
        :use-dynamic-row-height="true"
        styleClass="table condensed table-bordered vgt-table-report-print"
        :sort-options="{
          enabled: false,
        }"
        :group-options="{
          enabled: false,
        }"
        :pagination-options="{
          dropdownAllowAll: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { db } from "../../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"

export default {
  data() {
    return {
      filterDate: {
        start: this.$moment(new Date(), "DD/MM/YYYY"),
        end: this.$moment(new Date(), "DD/MM/YYYY"),
      },
      services: [],
      products: [],
      serviceColumns: [
        {
          label: "Item",
          field: "item_name",
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: "Item Code",
          field: "item_code",
          thClass: 'text-left',
          tdClass: 'text-left',
          width: '140px',
        },
        {
          label: "# of items",
          field: "quantity",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '95px',
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString()+" ฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '110px',
        },
        {
          label: "Discount",
          field: "discount",
          formatFn: (value) => value.toLocaleString()+" ฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '110px',
        },
        {
          label: "Total",
          field: "total",
          formatFn: (value) => value.toLocaleString()+" ฿",
          thClass: 'text-right',
          tdClass: 'text-right',
          width: '125px',
        },
      ],
      summaryColumns: [
        {
          label: "Type",
          field: "item_name",
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: "# of items",
          field: "quantity",
          formatFn: (value) => value.toLocaleString(),
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Amount",
          field: "amount",
          formatFn: (value) => value.toLocaleString()+" ฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Discount",
          field: "discount",
          formatFn: (value) => value.toLocaleString()+" ฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
        {
          label: "Total",
          field: "total",
          formatFn: (value) => value.toLocaleString()+" ฿",
          thClass: 'text-right',
          tdClass: 'text-right'
        },
      ],
    }
  },
  methods: {
    printReport() {
      const moment = require('moment')
      let start= ""
      let end= ""
      if(moment.isMoment(this.filterDate.start))
      {
        start = this.$moment(this.filterDate.start).format('DD/MM/YYYY')
        end = this.$moment(this.filterDate.end).format('DD/MM/YYYY')
      }
      else{
        start = this.filterDate.start
        end = this.filterDate.end
      }
      window.open(`/print/inventory-report/?start=${start}&end=${end}`)
    },
     exportCsv(datas,name) {
      try {
        const nopriceservices = datas.map((data) => ({
          Item_code: data.item_code,
          Item: data.item_name,
          Quantity: data.quantity,
        }))
        const parser = new Parser()
        const myData = JSON.parse(JSON.stringify(nopriceservices))
        const csv = parser.parse(myData)
        const anchor = document.createElement("a")
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
        anchor.target = "_blank"
        anchor.download = "data_"+name+".csv"
        anchor.click()
        anchor.remove()
      } catch (err) {
        console.error(err)
      }
    },    
    async search() {
      const loader = this.$loading.show({})
      await this.fetchInvoices(
        this.$moment(this.filterDate.start, "DD/MM/YYYY")
          .startOf("day")
          .toDate(),
        this.$moment(this.filterDate.end, "DD/MM/YYYY").endOf("day").toDate()
      )
      loader.hide()
    },
    async fetchInvoices(start, end) {
      let services = []
      let products = []
      const invoicesRef = await db
        .collection("Invoice")
        .where("issue_date", ">=", start)
        .where("issue_date", "<", end)
        .where("status", "in", ["paid", "outstanding", "gop"])
        .get()

      invoicesRef.docs.forEach((invoice) => {
        const data = invoice.data()
        services = services.concat(this.getServiceRevenue(data.billable_items))
        products = products.concat(this.getServiceRevenue(data.product_items))
      })

      const formattedServices = _.chain(services)
        .groupBy("item_code")
        .map((service, code) => ({
          item_code: code,
          item_name: service[0].item_name,
          quantity: _.sumBy(service, "quantity"),
          amount: _.sumBy(service, "amount"),
          discount: _.sumBy(service, "discount"),
          total: _.sumBy(service, "total"),
        }))

      const formattedProducts = _.chain(products)
        .groupBy("item_code")
        .map((service, code) => ({
          item_code: code,
          item_name: service[0].item_name,
          quantity: _.sumBy(service, "quantity"),
          amount: _.sumBy(service, "amount"),
          discount: _.sumBy(service, "discount"),
          total: _.sumBy(service, "total"),
        }))

      this.products = formattedProducts.value()
      this.services = formattedServices.value()
    },
    getServiceRevenue(services) {
      if (!services || !services.length) return []

      return services.map((item) => {
        const amount = Number(item.price * item.quantity)
        const discount = this.getDiscount(item)
        return {
          item_code: item.item_code,
          item_name: item.item.item_name,
          quantity: item.quantity,
          amount,
          discount,
          total: amount - discount,
        }
      })
    },
    getDiscount(item) {
      if (item.quantity && item.price) {
        return (
          Math.round(Number(item.quantity) *
          Number(item.price) *
          (Number(item.discount) / 100))
        )
      } else {
        return 0
      }
    },
    getSum(item, title) {
      return {
        item_name: title,
        quantity: _.sumBy(item, "quantity"),
        amount: _.sumBy(item, "amount"),
        discount: _.sumBy(item, "discount"),
        total: _.sumBy(item, "total"),
      }
    },
  },
  computed: {
    serviceSummary() {
      return [this.getSum(this.services, "Service")]
    },
    productSummary() {
      return [this.getSum(this.products, "Products")]
    },
    totalSummary() {
      return [
        this.getSum([...this.serviceSummary, ...this.productSummary], "Total"),
      ]
    },
  },
}
</script>
<style>
.no-link {
  text-decoration: none;
}
.vgt-table-report-print thead th {
  background-color: #2F6EBA;
  color: white;
}
.vgt-table-report-print tr:last-child td{
  background-color: #DAE2E8 !important;
  color: black !important;
  font-weight: bold;
}
</style>