import Inventory from "../pages/Inventory/Summary/";
import NewItem from "../pages/Inventory/New.vue";

export default [
  {
    path: "/inventory",
    name: "Inventory",
    component: Inventory
  },
  {
    path: "/inventory/new",
    name: "New Item",
    component: NewItem
  },
]