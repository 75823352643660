<template>
  <div class="mt-4">
    <div class="d-flex mb-2">
      <h3 class="text-primary">Payment</h3>

      <button class="btn btn-light ml-auto" @click="$emit('displaySummaryModal')" tabs v-if="!$route.meta.print">
        <i class="fas fa-print"></i> Print Payment Summary
      </button>
    </div>

    <payment-table :data="payments" @displayPaymentModal="showPayment" />
  </div>
</template>

<script>
import { db } from '../../../db'
import { capitalizeFirstChar } from '../../../helper'

export default {
  props: ['start', 'end'],
  data() {
    return {
      payments: []
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const start = this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate()
      const end = this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate()
      const payments = []

      const paymentsCollection = await db.collection('Payment')
        .where('issue_date', '>', start)
        .where('issue_date', '<', end)
        .get();

      paymentsCollection.docs.forEach(paymentCollection => {
        const payment = paymentCollection.data();
        const preparedPayments = [];

        if (payment.cash > 0) {
          preparedPayments.push({ name: 'Cash', amount: payment.cash });
        }
        if (payment.credit > 0) {
          preparedPayments.push({
            name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`,
            amount: payment.credit
          });
        }
        if (payment.insurance > 0) {
          preparedPayments.push({ name: 'Insurance', amount: payment.insurance });
        }
        if (payment.internet_banking > 0) {
          preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking });
        }
        if (payment.other > 0) {
          preparedPayments.push({
            name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other',
            amount: payment.other
          });
        }
        payment.total_amount = [payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking]
          .reduce((sum, value) => sum + Number(value || 0), 0);

        payment.preparedPayments = preparedPayments;
        payments.push(payment);
      });

      const paymentsByDate = _.groupBy(payments, payment => {
        return this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY");
      });

      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments
      }));

      loader.hide()
    },
    showPayment(paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    }
  }
}
</script>