<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body">
        <div class="text-muted">Inventory Management</div>

        <hr />
        <b-nav class="mb-2" tabs>
          <b-nav-item :active="currentMenu === menu.name" v-for="(menu, index) in menus" :key="index"
            @click="currentMenu = menu.name">{{ menu.name }}</b-nav-item>
        </b-nav>

        <div v-if="currentMenu === 'Product'">
          <product-tab />  
        </div>

        <div v-if="currentMenu === 'Billable Item'">
          <div class="d-flex">
            <h3 class="text-primary">Billable Item</h3>
            <button class="btn btn-primary ml-auto" >
              <i class="fas fa-file-import"></i> Import
            </button>                    
            <button class="btn btn-primary ml-2" @click="showBillableItem()">
              <i class="fas fa-plus"></i> Billable Item
            </button>
          </div>
          <div class="input-group mt-2 mb-2">
            <input type="text" class="form-control" focus
              placeholder="Scan Barcode or search by ID, generic name, brand name" v-model="keyword2" />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>

          <vue-good-table :columns="BillableItemColumns" :rows="filteredBillableItems" @on-row-click="showBillableItem"
            :pagination-options="{
              enabled: true,
            }" />
        </div>

        <div v-if="currentMenu === 'Package'">
          <div class="d-flex">
            <h3 class="text-primary">Package</h3>
            <button class="btn btn-primary ml-auto" @click="showPackage()">
              <i class="fas fa-plus"></i> New Package
            </button>
          </div>
          <div class="input-group mt-2 mb-2">
            <input type="text" class="form-control" focus
              placeholder="Scan Barcode or search by ID, generic name, brand name" v-model="keyword3" />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>

          <vue-good-table :columns="packageColumns" :rows="filteredPackages" :pagination-options="{
            enabled: true,
          }" @on-row-click="showPackage" />
        </div>

        <div v-if="currentMenu === 'Report'">
          <report-tab />
        </div>
      </div>
    </div>   

    <b-modal id="billable-item-modal" hide-footer size="xl" :title="'Billable Item'">
      <div class="row">
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item code'" v-model="billable_item.item_code" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item name'" v-model="billable_item.item_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item type'" v-model="billable_item.item_type" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Thai)'" v-model="billable_item.price_th" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Foreigner)'" v-model="billable_item.price_inter" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Insurance)'" v-model="billable_item.price_insurance" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Quantity'" v-model="billable_item.quantity" />
        </div>
        <div class="col-12 mt-2">
          <button class="btn btn-primary btn-block" @click="saveBillableItem">
            <i class="fas fa-save"></i> Save
          </button>
        </div>
        <div class="col-12 mt-2" v-if="isEditBillableItem">
          <button class="btn btn-danger btn-block" @click="deleteBillableItem(billable_item.item_code)">
            <i class="fas fa-trash"></i> Delete Billable Item
            {{ billable_item.item_code }}
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="package-modal" title="package" size="xl" hide-footer>
      <div class="d-flex mb-2 mt-2">
        <label>Package Code</label>
        <input type="text" class="form-control" v-model="med_package.package_code" />
      </div>
      <div class="d-flex mb-2 mt-2">
        <label>Package Name</label>
        <input type="text" class="form-control" v-model="med_package.package_name" />
      </div>
      <div class="d-flex p-2 bg-light">
        <h5 class="text-primary">BILLABLE ITEMS</h5>
      </div>
      <div class="container-fluid">
        <draggable v-model="med_package.billable_items" group="billable_items" @start="drag = true" @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in med_package.billable_items" :key="index">
            <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div>
            <div class="col-7">
              <label class="text-primary">Item</label>
              <MazSelect class="ml-auto" v-model="item.item_code" search :options="prepared_billable_items.map((i) => {
                return { value: i.item_code, label: i.item_name };
              })
                " />
            </div>
            <div class="col-1">
              <label class="text-primary">Quantity</label>
              <input type="number" class="form-control" v-model="item.quantity" />
            </div>
            <div class="col-2">
              <label class="text-primary">Discount</label>
              <b-input-group append="%">
                <b-form-input v-model="item.discount" type="number"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-1">
              <button class="btn btn-light" @click="med_package.billable_items.splice(index, 1)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <hr />
          </div>
        </draggable>
      </div>

      <div class="d-flex p-2 bg-light">
        <h5 class="text-primary">PRODUCTS ITEMS</h5>
      </div>
      <div class="container-fluid">
        <draggable v-model="med_package.product_items" group="med_package.products" @start="drag = true"
          @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in med_package.product_items" :key="index">
            <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div>
            <div class="col-7">
              <label class="text-primary">Item</label>
              <MazSelect class="ml-auto" v-model="item.item_code" search :options="prepared_products.map((i) => {
                return { value: i.item_code, label: i.item_name };
              })
                " />
            </div>
            <div class="col-1">
              <label class="text-primary">Quantity</label>
              <input type="number" class="form-control" v-model="item.quantity" />
            </div>
            <div class="col-2">
              <label class="text-primary">Discount</label>
              <b-input-group append="%">
                <b-form-input v-model="item.discount" type="number"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-1">
              <button class="btn btn-light" @click="med_package.product_items.splice(index, 1)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <hr />
          </div>
        </draggable>
      </div>

      <div class="d-flex p-2 bg-gray">
        <button class="btn btn-primary mr-2" @click="addBillableItem">
          <i class="fas fa-plus"></i> Add Billable Item
        </button>
        <button class="btn btn-primary mr-2" @click="addProduct">
          <i class="fas fa-plus"></i> Add Product
        </button>
      </div>
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="savePackage">
          <i class="fas fa-save"></i> Save
        </button>
      </div>
      <div class="d-flex mt-2" v-if="isEditPackage">
        <button class="btn btn-danger btn-block" @click="deletePackage(med_package.package_code)">
          <i class="fas fa-trash"></i> Delete Package:
          {{ med_package.package_code }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { db, Timestamp } from "../../../db";
import draggable from "vuedraggable";
import Parser from "@json2csv/plainjs/dist/cjs/Parser";
import Product from './Product.vue';
import Report from "./Report.vue";
import { each, reject } from "lodash";

export default {
  components: {
    draggable,
    "product-tab": Product,
    "report-tab": Report,
  },
  data() {
    return {      
      keyword2: "",
      keyword3: "",    
      medicines: [],
      billable_items: [],
      packages: [],      
      productsFileData: "",
      billableItemsFileData: "",
      currentMenu: "Product",
      preparedProductsLength: null,
      preparedBillableItemsLength: null,
      preparedProducts: {},
      preparedBillableItems: {},
      product: {},
      billable_item: {},
      med_package: {
        package_name: null,
        package_code: null,
        billable_items: [],
        product_items: [],
      },
      isEditBillableItem: false,
      isEditPackage: false,
      BillableItemColumns: [
        {
          label: "Item Code",
          field: "item_code",
        },
        {
          label: "Item Name",
          field: "item_name",
        },
        {
          label: "Item Type",
          field: "item_type",
        },
        {
          label: "Price",
          field: "price_inter",
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      packageColumns: [
        {
          label: "Package Code",
          field: "package_code",
        },
        {
          label: "Package Name",
          field: "package_name",
        },
      ],      
      billablesheetTitle: "BILLABLE",
    };
  },
  firestore() {
    return {
      medicines: db.collection("Product"),
      billable_items: db.collection("BillableItem"),
      packages: db.collection("Package"),
    };
  },
  methods: {    
    showBillableItem(params) {
      if (params) {
        console.log({ params })
        this.billable_item = { ...params.row }
        this.isEditBillableItem = true
      } else {
        this.isEditBillableItem = false
        this.billable_item = {}
      }
      this.$bvModal.show("billable-item-modal")
    },    
    async saveBillableItem() {
      let loader = this.$loading.show({})
      if (!this.billable_item.item_code || !this.billable_item.item_name) {
        this.$alert("please enter item code and item name", null, "error")
        loader.hide()
      }

      try {
        let billable_item = { ...this.billable_item }
        await db
          .collection("BillableItem")
          .doc(this.billable_item.item_code)
          .set(billable_item)
        loader.hide();
        this.$alert("success", null, "success")
      } catch (error) {
        this.$alert(`error ${error}`, null, "error")
        loader.hide()
      }
    },     
    showPackage(med_package) {
      if (med_package) {
        this.med_package = {...med_package.row}
        this.isEditPackage = true
      } else {
        this.isEditPackage = false
        this.med_package = {}    
      }
      this.$bvModal.show("package-modal")
    },
    async savePackage() {
      let loader = this.$loading.show({})

      await db
        .collection("Package")
        .doc(this.med_package.package_code)
        .set({
          ...this.med_package,
        });

      loader.hide();
    },
        
    addBillableItem() {
      this.med_package.billable_items.push({ item_code: null, quantity: 1 })
    },    
    deleteBillableItem(id) {
      this.$confirm("do you want to delete this billableitem?").then(
        async () => {
          let loader = this.$loading.show({});
          await db.collection("BillableItem").doc(id).delete();

          loader.hide();
          this.$bvModal.hide("billable-item-modal");

          this.$alert(`billable item ${id} was deleted.`, null, "success");
        }
      );
    },
    deletePackage(id) {
      this.$confirm("do you want to delete this package?").then(async () => {
        let loader = this.$loading.show({});
        await db.collection("Package").doc(id).delete();

        loader.hide();
        this.$bvModal.hide("package-modal");

        this.$alert(`package ${id} was deleted.`, null, "success");
      });
    },
  },
  computed: {
    prepared_products() {
      return this.medicines.map((i) => {
        return {
          id: i.id,
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null,
        };
      });
    },
    prepared_billable_items() {
      return this.billable_items.map((i) => {
        return {
          id: i.id,
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null,
        }
      })
    },
    filteredBillableItems() {
      let items = [];
      if (this.keyword2) {
        for (let item of this.billable_items) {
          if (
            item.id.indexOf(this.keyword2.toUpperCase()) !== -1 ||
            (item.item_name &&
              item.item_name
                .toUpperCase()
                .indexOf(this.keyword2.toUpperCase()) !== -1)
          )
            items.push(item);
        }
        return items;
      } else {
        return this.billable_items;
      }
    },
    filteredPackages() {
      let items = [];
      if (this.keyword3) {
        for (let item of this.packages) {
          if (
            item.id.indexOf(this.keyword3.toUpperCase()) !== -1 ||
            (item.package_name &&
              item.package_name
                .toUpperCase()
                .indexOf(this.keyword3.toUpperCase()) !== -1)
          )
            items.push(item);
        }
        return items;
      } else {
        return this.packages;
      }
    },
    menus() {
      const menus = [
        { name: "Product" },
        { name: "Billable Item" },
        { name: "Package" },
      ];

      if (this.$store.getters.permission["Inventory Report"] === 5)
        menus.push({ name: "Report" });
      return menus;
    },
  },
};
</script>