var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-good-table',{attrs:{"columns":_vm.paymentColumns,"rows":_vm.data,"use-dynamic-row-height":true,"calculate-width-by-content":true,"sort-options":{
    enabled: false,
  },"group-options":{
    enabled: true
  },"pagination-options":{
    enabled: !this.$route.meta.print,
    enabled: true,
    perPageDropdown: [30, 40, 50, 100, 200],
    dropdownAllowAll: false,
  },"search-options":{
      enabled: true,
  }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'issue_date')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.issue_date.toDate(),'HH:mm'))+" ")]):(props.column.field == 'payment_id')?_c('span',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.showPayment(props.row.payment_id)}}},[_vm._v(_vm._s(props.row.payment_id))])]):(props.column.field == 'patient_name')?_c('span',[_c('a',{attrs:{"target":"_blank","href":`/patient/${props.row.HN}`}},[_vm._v(_vm._s(props.row.patient_name))])]):(props.column.field == 'payment_type')?_c('span',_vm._l((props.row.preparedPayments),function(p,index){return _c('span',{key:`type_${index}`},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.showPayment(props.row.payment_id)}}},[_vm._v(" "+_vm._s(index+1)+". "+_vm._s(p.name)+" ")]),_c('br')])}),0):(props.column.field == 'payment_amount')?_c('span',_vm._l((props.row.preparedPayments),function(p,index){return _c('span',{key:`amount_${index}`},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.showPayment(props.row.payment_id)}}},[_vm._v(" "+_vm._s(index+1)+". "+_vm._s(p.amount.toLocaleString())+" ")]),_c('br')])}),0):(props.column.label == 'Total Amount')?_c('span',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.showPayment(props.row.payment_id)}}},[_vm._v(_vm._s(props.row.total_amount.toLocaleString())+" ")])]):(props.column.label == 'Print')?_c('div',[_c('button',{staticClass:"btn btn-light text-white"},[_c('a',{attrs:{"target":"_blank","href":`/print/receipt/${props.formattedRow['payment_id']}`}},[_c('i',{staticClass:"fas fa-print"})])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }