import { render, staticRenderFns } from "./PrintInvoice.vue?vue&type=template&id=1878a7ff&scoped=true"
import script from "./PrintInvoice.vue?vue&type=script&lang=js"
export * from "./PrintInvoice.vue?vue&type=script&lang=js"
import style0 from "./PrintInvoice.vue?vue&type=style&index=0&id=1878a7ff&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1878a7ff",
  null
  
)

export default component.exports