<template>
  <vue-good-table
    :columns="paymentColumns"
    :rows="data"
    :use-dynamic-row-height="true"
    :calculate-width-by-content="true"
    :sort-options="{
      enabled: false,
    }"
    :group-options="{
      enabled: true
    }"
    :pagination-options="{
      enabled: !this.$route.meta.print,
      enabled: true,
      perPageDropdown: [30, 40, 50, 100, 200],
      dropdownAllowAll: false,
    }"
    :search-options="{
        enabled: true,
    }" 
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'issue_date'">
        {{ props.row.issue_date.toDate() | moment('HH:mm') }}
      </span>
      <span v-else-if="props.column.field == 'payment_id'">
        <a href="#" @click="showPayment(props.row.payment_id)">{{ props.row.payment_id }}</a>
      </span>
      <span v-else-if="props.column.field == 'patient_name'">
        <a target="_blank" :href="`/patient/${props.row.HN}`">{{ props.row.patient_name }}</a>
      </span>
      <span v-else-if="props.column.field == 'payment_type'">
        <span v-for="(p, index) in props.row.preparedPayments" :key="`type_${index}`" >
          <a href="#" @click="showPayment(props.row.payment_id)" >
            {{ index+1 }}. {{ p.name }}
          </a>
          <br />
        </span>
      </span>
      <span v-else-if="props.column.field == 'payment_amount'">
        <span v-for="(p, index) in props.row.preparedPayments" :key="`amount_${index}`" >
          <a href="#" @click="showPayment(props.row.payment_id)" >
            {{ index+1 }}. {{ p.amount.toLocaleString() }}
          </a>
          <br />
        </span>
      </span>
      <span v-else-if="props.column.label == 'Total Amount'">
        <a href="#" @click="showPayment(props.row.payment_id)" >{{ props.row.total_amount.toLocaleString() }} </a>
      </span>
      <div v-else-if="props.column.label == 'Print'">
        <button class="btn btn-light text-white" >
          <a target="_blank" :href="`/print/receipt/${props.formattedRow['payment_id']}`"><i class="fas fa-print"></i></a>
        </button>
      </div>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      paymentColumns: [
        {
          label: 'Date time',
          field: 'issue_date',
        },
        {
          label: 'Payment#',
          field: 'payment_id',
        },
        {
          label: 'Patient Name',
          field: 'patient_name',
        },
        {
          label: 'Payment Type',
          field: 'payment_type',
        },
        {
          label: 'Payment Amount',
          field: 'payment_amount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'Total Amount',
          field: 'total_amount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'Print',
          field: 'print',
          hidden: !!this.$route.meta.print
        }
      ]
    }
  },
  methods: {
    showPayment (paymentId) {
      this.$emit('displayPaymentModal', paymentId)
    }
  }
}
</script>