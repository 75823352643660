<template>
  <div class="row p-2">
    <div class="col-12">
      <h3 class="text-primary text-center">Register Time Range</h3>
      <hr>
    </div>
    <div class="col-12">
      <div class="d-flex">
        <label>between: </label>
        <MazPicker v-model="from" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
        <label>and</label>
        <MazPicker v-model="to" clearable no-time format="DD/MM/YYYY" formatted="DD/MM/YYYY" />
      </div>
    </div>
    <div class="col-6 mt-2">
      <table class="table table-bordered">
        <thead>
          <th></th>
          <th>HOSPITAL</th>
          <th>CLINIC</th>
          <th>TOTAL</th>
        </thead>
        <tr v-for="(label, index) in chartData.labels" :key="label">
          <td>{{label}}</td>
          <td>{{chartData.datasets[0].data[index]}}</td>
          <td>{{chartData.datasets[1].data[index]}}</td>
          <td>{{chartData.datasets[0].data[index]+chartData.datasets[1].data[index]}}</td>
        </tr>
        <tr>
          <td>SUM</td>
          <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
          <td>{{ chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) + chartData.datasets[1].data.reduce((partialSum, a) => partialSum + a, 0) }}</td>
        </tr>
      </table>
    </div>
    <div class="col-6 mt-2">
      <LineChartGenerator 
        :chart-options="chartOptions" 
        :chart-data="chartData" 
        :dataset-id-key="'label'"
        :chart-id="'patients_number_chart'" 
        :width="400" 
        :height="400" />
    </div>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import Chart from 'chart.js/auto';

export default {
  props: ['patients'],
  components: {
    LineChartGenerator
  },
  data() {
    return {
      from: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      to: this.$moment().format('DD/MM/YYYY'),
      chartOptions: {
        responsive: true
      }
    }
  },
  computed: {
    chartData() {

      let filteredPatients = []

      let fromMoment = this.$moment(this.from, 'DD/MM/YYYY')
      let toMoment = this.$moment(this.to, 'DD/MM/YYYY')

      for(let p of this.patients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          if (
              this.$moment(p.created_at.toDate()).isSameOrBefore(toMoment) && 
              this.$moment(p.created_at.toDate()).isSameOrAfter(fromMoment)
            ) filteredPatients.push(p)
        }
      }

      // generate months labels
      //label
      let labels = []
      let startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')

      for(let i = 0; i<24; i++) {
        labels.push(startLabelMoment.format('HH:mm'))
        startLabelMoment.add(1, 'hour')
      }

      //data
      startLabelMoment = this.$moment(fromMoment, 'DD/MM/YYYY')
      let hospitalData = []
      let clinicData = []
      let totalData = []

      for(let i = 0; i<24; i++) {
        hospitalData.push(0)
        clinicData.push(0)
        totalData.push(0)
      }


      for (let p of filteredPatients) {
        if (p.created_at && typeof p.created_at !== 'string') {
          let hour = this.$moment(p.created_at.toDate()).hour()
          if(p.branch === 1) hospitalData[hour]++
          if(p.branch === 2) clinicData[hour]++
          totalData[hour]++
        }
      }

      return {
        labels,
        datasets: [
          {
            label: 'Hospital',
            data: hospitalData,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgb(54, 162, 235)',
              
            ],
            borderWidth: 1,
            suggestedMin: -10,
            suggestedMax: 200,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Clinic',
            data: clinicData,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: [
              'rgb(255, 99, 132)',
            ],
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },
          {
            label: 'Total',
            data: totalData,
            borderWidth: 1,
            cubicInterpolationMode: 'monotone'
          },

        ]
      }
    }
  }
}
</script>