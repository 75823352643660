<template>
  <div class="container-fluid" id="element-to-convert">
    <div class="d-flex">
      <h3 class="text-primary">Products</h3>
      <button class="btn btn-primary ml-auto" @click="$bvModal.show('import-product')">
        <i class="fas fa-file-import"></i> Import
      </button>
      <button class="btn btn-primary ml-2" @click="showProduct()">
        <i class="fas fa-plus"></i> New Products
      </button>
    </div>
    <b-modal id="import-product" title="Import Product" hide-footer>
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="exportCsv(filteredMedicines, 'selectedData')">
          Download CSV
        </button>
      </div>
      <hr />
      <hr />
      <div class="d-flex mt-2">
        <button class="btn btn-primary btn-block" @click="$refs['products-csv'].click()">
          Upload CSV
        </button>
        <input id="products-csv" ref="products-csv" type="file" hidden
          accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          @change="uploadCsv" />
      </div>
    </b-modal>
    <div class="input-group mt-2 mb-2">
      <input type="text" class="form-control" focus placeholder="Scan Barcode or search by ID, generic name, brand name"
        v-model="keyword1" />
      <div class="input-group-append">
        <span class="input-group-text">
          <i class="fas fa-search"></i>
        </span>
      </div>
    </div>

    <vue-good-table :columns="medicineColumns" :rows="filteredMedicines" @on-row-click="showProduct"
      :pagination-options="{
        enabled: true,
      }" />
    <b-modal id="product-modal" hide-footer size="xl" :title="'Product'">
      <div class="row">
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item code'" v-model="product.item_code" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item name'" v-model="product.item_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Other name'" v-model="product.item_other_name" />
        </div>
        <div class="col-12 mt-2">
          <MazInput :placeholder="'Item type'" v-model="product.item_type" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Thai)'" v-model="product.price_th" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Foreigner)'" v-model="product.price_inter" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Price (Insurance)'" v-model="product.price_insurance" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="text" :placeholder="'Supplier'" v-model="product.supplier" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Cost'" v-model="product.cost" />
        </div>
        <div class="col-12 mt-2">
          <MazInput type="number" :placeholder="'Quantity'" v-model="product.quantity" />
        </div>
        <div class="col-12 mt-2">
          <button class="btn btn-primary btn-block" @click="saveProduct">
            <i class="fas fa-save"></i> Save {{ product.id }}
          </button>
        </div>
        <div class="col-12 mt-2" v-if="isEditProduct">
          <button class="btn btn-danger btn-block" @click="deleteProduct(product.item_code)">
            <i class="fas fa-trash"></i> Delete Product {{ product.item_code }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>

</template>
<script>
import { db, Timestamp } from "../../../db"
import Parser from "@json2csv/plainjs/dist/cjs/Parser"

export default {
  components: {
  },
  data() {
    return {
      keyword1: "",
      medicines: [],
      productsFileData: "",
      preparedProductsLength: null,
      preparedProducts: {},
      product: {},
      isEditProduct: false,
      medicineColumns: [
        {
          label: "Item Code",
          field: "item_code",
        },
        {
          label: "Item Name",
          field: "item_name",
        },
        {
          label: "Item Type",
          field: "item_type",
        },
        {
          label: "Price",
          field: "price_inter",
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "Quantity",
          field: "quantity",
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      productsheetTitle: "PRODUCT",
    }
  },
  firestore() {
    return {
      medicines: db.collection("Product"),
    }
  },
  methods: {
    CSVToArray(strData, strDelimiter) {

      strDelimiter = (strDelimiter || ",")

      var objPattern = new RegExp(
        (
          // Delimiters.
          "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
          // Standard fields.
          "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
      )
      var arrData = [[]]
      var arrMatches = null
      while (arrMatches = objPattern.exec(strData)) {
        var strMatchedDelimiter = arrMatches[1]
        if (
          strMatchedDelimiter.length &&
          strMatchedDelimiter !== strDelimiter
        ) {
          arrData.push([])
        }
        var strMatchedValue;
        if (arrMatches[2]) {
          strMatchedValue = arrMatches[2].replace(
            new RegExp("\"\"", "g"),
            "\""
          )
        } else {
          strMatchedValue = arrMatches[3]
        }
        arrData[arrData.length - 1].push(strMatchedValue)
      }
      return (arrData)
    },
    csvArrayToObj(csvData) {
      return csvData
        .map((csvLine, csvIndex) => {
          if (csvIndex === 0 || !csvLine.length) return null // skip header and empty lines
          return csvLine.reduce((a, v, i) => ({ ...a, [csvData[0][i]]: v }), {})
        })
        .filter((filter) => !!filter) //filter empty lines
    },

    exportCsv(datas, name) {
      try {
        const selectedData = datas.map((data) => ({
          Item_code: data.item_code,
          Item: data.item_name,
          Quantity: 0,
        }))
        const parser = new Parser()
        const myData = JSON.parse(JSON.stringify(selectedData))
        const csv = parser.parse(myData)
        const anchor = document.createElement("a")
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
        anchor.target = "_blank"
        anchor.download = "data_" + name + ".csv"
        anchor.click()
        anchor.remove()
      } catch (err) {
        console.error(err)
      }
    },
    async uploadCsv() {
      let promise = new Promise((resolve, reject) => {
        const reader = new FileReader()
        const fileSelector = document.getElementById("products-csv")
        const file = fileSelector.files[0]
        reader.onload = (e) => {
          resolve((this.productsFileData = reader.result))
        }
        reader.onerror = reject
        reader.readAsText(file)
      })
      promise.then(async (result) => {
        this.preparedProducts = this.CSVToArray(this.productsFileData)
        this.preparedProducts = this.csvArrayToObj(this.preparedProducts)
        for (const product of this.preparedProducts) {
          const quantity = Number(product.Quantity) || 0
          if (quantity <= 0) return

          const result = await db.collection('Product').doc(product.Item_code).get()
          const data = result.data()
          const updatedQuantity = (Number(data.quantity) || 0) + quantity
          const payload = {
            ...data,
            quantity: updatedQuantity
          }

          try {
            await db.collection('Product').doc(product.Item_code).set(payload)
          } catch (error) {
            console.log(error)
          }
        }


      })

    },
    showProduct(params) {
      if (params) {
        console.log({ params })
        this.product = {
          id: params.row.id,
          ...params.row,
        };
        this.isEditProduct = true
      } else {
        this.isEditProduct = false
        this.product = {}
      }
      this.$bvModal.show("product-modal")
    },
    async saveProduct() {
      let loader = this.$loading.show({})
      if (!this.product.item_code || !this.product.item_name) {
        this.$alert("please enter item code and item name", null, "error")
        loader.hide()
      }

      try {
        let product = { ...this.product }
        product.id = product.id || null
        product.cost = product.cost || null
        product.price_insurance = product.price_insurance || null
        product.price_th = product.price_th || null
        product.price_inter = product.price_inter || null
        product.item_other_name = product.item_other_name || null
        product.item_type = product.item_type || null
        product.quantity = product.quantity || null

        await db.collection("Product").doc(product.item_code).set(product)
        loader.hide()
        this.$alert("success", null, "success")
      } catch (error) {
        this.$alert(`error ${error}`, null, "error")
        loader.hide()
      }
    },
    addProduct() {
      this.med_package.product_items.push({ item_code: null, quantity: 1 })
    },
    deleteProduct(id) {
      this.$confirm("do you want to delete this product?").then(async () => {
        let loader = this.$loading.show({})
        await db.collection("Product").doc(id).delete()

        loader.hide()
        this.$bvModal.hide("product-modal")

        this.$alert(`product ${id} was deleted.`, null, "success")
      })
    },

  },
  computed: {
    prepared_products() {
      return this.medicines.map((i) => {
        return {
          id: i.id,
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null,
        }
      })
    },

    filteredMedicines() {
      let medicines = []
      if (this.keyword1) {
        for (let item of this.medicines) {
          if (
            item.id.indexOf(this.keyword1.toUpperCase()) !== -1 ||
            (item.item_name &&
              item.item_name
                .toUpperCase()
                .indexOf(this.keyword1.toUpperCase()) !== -1)
          )
            medicines.push(item)
        }
        return medicines
      } else {
        return this.medicines
      }
    },

  },
}
</script>