<template>
  <div>
    <b-modal id="new-patient-invoice-modal" hide-footer size="xl" header-bg-variant="gray" :title="`${invoice.invoice_id ? 'Invoice #' + invoice.invoice_id : 'New Invoice'}`" >
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-1">
            <div class="text-primary">From</div>
          </div>
          <div class="col-3">
            <b>WORLDMED CENTER</b>
            <p>125/131-133, M.7 Phi Phi Island, T.Aonang A.MuangKrabi Krabi, 81210 Thailand</p>
            <div class="mt-2">
              <b>WORLDMED CENTER Co., Ltd.</b>
              <div>0835557004354</div>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Patient: </label>
              <span v-if="invoice.HN">{{ invoice.patient_name }}</span>
              <span v-else>SELECT SOMETHING</span>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Billed to:</label>
              <textarea type="text" class="form-control" v-model="invoice.billed_to" ></textarea>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Concession type:</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.concession_type"
                :options="['Resident', 'Foreigner', 'Insurance']"
                @input="handleConcessionChange"
              />
            </div>
            <div class="d-flex mb-2" v-if="invoice.concession_type === 'Insurance'">
              <label class="text-primary">Insurance: </label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.insurance_name"
                :options="[
                  'Other',
                  ..._.sortBy($store.state.insurances.map(c => {
                    return c.name_en
                  }))
                ]"
              />
            </div>
            <div class="d-flex mb-2" v-if="invoice.insurance_name === 'Other'" >
              <label class="text-primary">Other insurance: </label>
              <input 
                type="text" 
                class="form-control ml-2" 
                v-model="invoice.insurance_name_other"
                placeholder="Other Insurance Name">
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Status: </label>
              <object-select
                class="ml-2"
                v-model="invoice.status"
                :options="[
                  { id: 'unpaid', name: 'Unpaid' },
                  { id: 'waiting for payment', name: 'Waiting for Payment' },
                  { id: 'denied', name: 'Denied' },
                  { id: 'outstanding', name: 'Outstanding' },
                  { id: 'gop', name: 'GOP' },
                  { id: 'paid', name: 'Paid' },
                  { id: 'canceled', name: 'Canceled' },
                ]"
              />
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Type:</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.type"
                :options="['OPD', 'IPD']"
              />
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-1">Discount:</label>
              <object-select
                class="ml-2"
                v-model="invoice.discount"
                :options="preparedDiscount"
              />
              <button class="btn btn-primary ml-2" @click="applyDiscount(invoice.discount)">Apply</button>
            </div>
          </div>

          <div class="col-4">
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Issue Date</label>
              <MazPicker v-model="invoice.issue_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`" placeholder="Issue Date" />
            </div>
            <div class="d-flex mb-2" v-if="invoice.invoice_id">
              <label class="text-primary mr-auto">Invoice#</label>
              <div class="text-right">{{ invoice.invoice_id }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">HN</label>
              <div class="text-right">{{ invoice.HN }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Visit Date</label>
              <div>
                <object-select
                  class="ml-2"
                  v-model="invoice.VN"
                  mode="required"
                  :options="visits.map(item => {
                    return {
                      id: item.VN,
                      name: `${$moment(item.visit_date.toDate()).format('DD/MM/YYYY HH:mm')}`
                    }
                  })"
                />
              </div>
            </div>
            <!-- <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Visit Date</label>
              <MazPicker v-model="invoice.visit_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`" placeholder="Visit Date" />
            </div> -->
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Patient</label>
              <div class="text-right">{{ invoice.patient_name }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Practitioner</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.practitioner"
                :options="doctors.map(d => {
                  return d.displayName
                })"
              />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="d-flex p-2 bg-light">
        <h5 class="text-primary">BILLABLE ITEMS</h5>
      </div>
      <div class="container-fluid">
        <draggable v-model="invoice.billable_items" group="billable_items" @start="drag = true" @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in invoice.billable_items" :key="index">
            <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div>
            <div class="col-4">
              <label class="text-primary">Item</label>
              <object-select
                class="ml-auto"
                v-model="item.item_code"
                :options="prepared_billable_items.map(i => {
                  return { id: i.item_code, name: i.item_name }
                })"
                @input="handleBillableItemChanged(index)"
              />
            </div>
            <div class="col-1">
              <label class="text-primary">Quantity</label>
              <input type="number" class="form-control" v-model="item.quantity">
            </div>
            <div class="col-2">
              <label class="text-primary">Unit Price</label>
              <input-number v-model="item.price"></input-number>
            </div>
            <div class="col-2">
              <label class="text-primary">Discount</label>
              <b-input-group append="%">
                <b-form-input v-model="item.discount" type="number"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-1 text-right">
              <label class="text-primary ml-auto">Total</label>
              <div v-if="item.quantity && item.price">{{ Math.ceil(((item.price - (item.price * item.discount / 100)) * item.quantity)).toLocaleString() }}</div>
            </div>
            <div class="col-1">
              <button class="btn btn-light" @click="invoice.billable_items.splice(index, 1)"><i class="fas fa-trash"></i></button>
            </div>
            <hr>
          </div>
        </draggable>
      </div>

      <div class="d-flex p-2 bg-light">
        <h5 class="text-primary">PRODUCTS ITEMS</h5>
      </div>
      <div class="container-fluid">
        <draggable v-model="invoice.product_items" group="invoice.product_items" @start="drag = true" @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in invoice.product_items" :key="index">
            <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div>
            <div class="col-4">
              <label class="text-primary">Item</label>
              <object-select
                class="ml-auto"
                v-model="item.item_code"
                :options="prepared_products.map(i => {
                  return { id: i.item_code, name: i.item_name }
                })"
                @input="handleProductChanged(index)"
              />
            </div>
            <div class="col-1">
              <label class="text-primary">Quantity</label>
              <input type="number" class="form-control" v-model="item.quantity">
            </div>
            <div class="col-2">
              <label class="text-primary">Unit Price</label>
              <input type="number" class="form-control" v-model="item.price">
            </div>
            <div class="col-2">
              <label class="text-primary">Discount</label>
              <b-input-group append="%">
                <b-form-input v-model="item.discount" type="number"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-1 text-right">
              <label class="text-primary ml-auto">Total</label>
              <div v-if="item.quantity && item.price">{{ Math.ceil(((item.price - (item.price * item.discount / 100)) * item.quantity)).toLocaleString() }}</div>
            </div>
            <div class="col-1">
              <button class="btn btn-light" @click="invoice.product_items.splice(index, 1)"><i class="fas fa-trash"></i></button>
            </div>
            <hr>
          </div>
        </draggable>
      </div>

      <div class="d-flex p-2 bg-gray">
        <button class="btn btn-primary mr-2" @click="addBillableItem">
          <i class="fas fa-plus"></i> Add Billable Item
        </button>
        <button class="btn btn-primary mr-2" @click="addProduct">
          <i class="fas fa-plus"></i> Add Product
        </button>
        <button class="btn btn-primary mr-2" @click="showPackage">
          <i class="fas fa-plus"></i> Add Package
        </button>
      </div>

      <div class="container-fluid mt-4">
        <div class="row">
          <div class="col-5">
            <div class="d-flex mb-2">
              <b class="mr-auto">Total Discount</b>
              <div>{{ totalDiscount.toLocaleString() }}
              </div>
            </div>
            <div class="d-flex mb-2">
              <b class="mr-auto">Total Amount</b>
              <div>{{ totalAmount.toLocaleString() }}</div>
            </div>
          </div>
          <div class="col-7">
            <label class="text-primary">Note:</label>
            <textarea class="form-control" v-model="invoice.note"/>
          </div>
        </div>

        <label class="text-primary mt-4" v-if="invoice.history">History</label>
        <div class="row mt-2" v-if="invoice.history && invoice.history.length>0">
          <div class="col-12 text-grey" v-for="(item, index) of invoice.history" :key="index">
            [{{ item.datetime.toDate() | moment('DD/MM/YYYY HH:mm') }}] User: <span class="text-primary">{{item.user.displayName}} ({{ item.user.uid }})</span> Action:<span class="text-primary">{{ item.type }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2 mb-2">
        <router-link v-if="invoice.invoice_id" :to="`/print/invoice/${invoice.invoice_id}`" target="_blank" class="btn btn-light"
          style="margin-left: 10px; width: 150px;">
          <i class="fas fa-print"></i> Print Invoice
        </router-link>
        <router-link v-if="invoice.invoice_id" :to="`/print/invoice/${invoice.invoice_id}?mode=short`" target="_blank" class="btn btn-light"
          style="margin-left: 10px; width: 150px;">
          <i class="fas fa-print"></i> Print Short Invoice
        </router-link>
        <button v-if="invoice.invoice_id && invoice.status!=='paid'" class="btn btn-primary ml-2" @click="showNewPayment(invoice)">
          <i class="fas fa-money-bill-wave"></i> New Payment
        </button>
        <button class="btn btn-success ml-auto" @click="saveInvoice"><i class="fas fa-save"></i> Save Invoice</button>
        <button v-if="invoice.invoice_id && this.$store.getters.permission['Invoice'] === 5" class="btn btn-danger ml-2" @click="deleteInvoice(invoice.invoice_id)"><i class="fas fa-trash"></i> Delete Invoice</button>
      </div>
    </b-modal>

    <b-modal id="new-patient-invoice-package-modal" hide-footer size="xl" header-bg-variant="gray" :title="'Add package now'">
      <div class="container-fluid">
        <MazSelect
          class="ml-auto"
          v-model="selectedPackage"
          search
          :options="prepared_packages.map(i => {
            return { value: i, label: i.package_name }
          })"
        />
      </div>

      <div class="mt-2 mb-2" v-if="selectedPackage">
        <div>Billable Items</div>
        <ul>
          <li v-for="(item, index) in selectedPackage.billable_items" :key="`${item.item_code}-${index}`">[{{ item.item_code }}] {{ _.find(billable_items, { 'item_code': item.item_code }).item_name }}</li>
        </ul>
      </div>
      <div class="mt-2 mb-2" v-if="selectedPackage">
        <div>Products Items</div>
        <ul>
          <li v-for="(item, index) in selectedPackage.product_items" :key="`${item.item_code}-${index}`">[{{ item.item_code }}] {{ _.find(products, { 'item_code': item.item_code }).item_name }}</li>
        </ul>
      </div>
  
      <div class="d-flex mt-2 mb-2">
        <button class="btn btn-primary btn-block" @click="addPackage(selectedPackage)"><i class="fas fa-plus"></i> Add</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { db, Timestamp } from '../../db'
import draggable from 'vuedraggable'

export default {
  props: ['invoice', 'visits'],
  components: {
    draggable
  },
  data() {
    return {
      doctors: [],
      products: [],
      billable_items: [],
      packages: [],
      selectedPackage: null,
    }
  },
  firestore() {
    return {
      doctors: db.collection('User').where('roles', 'array-contains', 'doctor').where('isApproved', '==', true),
      products: db.collection('Product'),
      billable_items: db.collection('BillableItem'),
      packages: db.collection('Package')
    }
  },
  methods: {
    async fetchPatients() {
      const patients = (await db.collection('Patient')
        .select('HN', 'fisrt_name', 'last_name')
        .orderBy('created_at', 'desc')
        .get()).docs.map(patient => patient.data())
    },
    handleConcessionChange() {
      for (let item of this.invoice.product_items) {
        switch (this.invoice.concession_type) {
          case 'Insurance':
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_insurance
            break;
          case 'Resident':
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_th
            break;
          default:
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_inter
            break;
        }
      }
      for (let item of this.invoice.billable_items) {
        switch (this.invoice.concession_type) {
          case 'Insurance':
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_insurance
            break;
          case 'Resident':
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_th
            break;
          default:
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_inter
            break;
        }
      }
    },
    applyDiscount(discount) {
      this.invoice.product_items.map( item => {
        item.discount = Number(discount)
      })
      this.invoice.billable_items.map( item => {
        item.discount = Number(discount)
      })
    },
    handleBillableItemChanged(index) {
      switch (this.invoice.concession_type) {
        case 'Insurance':
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_insurance
          break;
        case 'Resident':
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_th
          break;
        default:
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_inter
          break;
      }

      this.invoice.billable_items[index].item = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code })
    },
    handleProductChanged(index) {
      switch (this.invoice.concession_type) {
        case 'Insurance':
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_insurance
          break;
        case 'Resident':
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_th
          break;
        default:
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_inter
          break;
      }
      this.invoice.product_items[index].item = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code })
    },
    addProduct() {
      this.invoice.product_items.push({ price: 0, quantity: 1, discount: 0 })
    },
    addBillableItem() {
      this.invoice.billable_items.push({ price: 0, quantity: 1, discount: 0 })
    },
    showPackage() {
      this.selectedPackage = null
      this.$bvModal.show('new-patient-invoice-package-modal')
    },
    addPackage(med_package) {
      for (const item of med_package.billable_items) {
        const billable_item = _.find(this.prepared_billable_items, { 'item_code': item.item_code })
        const data = {
          item_code: item.item_code,
          item: billable_item,
          discount: billable_item.discount ? billable_item.discount : 0,
          quantity: item.quantity || 1
        }

        switch (this.invoice.concession_type) {
          case 'Insurance':
            data.price = billable_item.price_insurance
            break;
          case 'Resident':
            data.price = billable_item.price_th
            break;
          default:
            data.price = billable_item.price_inter
            break;
        }

        this.invoice.billable_items.push(data)
      }
      for (const item of med_package.product_items) {
        const product_item = _.find(this.prepared_products, { 'item_code': item.item_code })
        const data = {
          item_code: item.item_code,
          item: product_item,
          discount: product_item.discount ? product_item.discount : 0,
          quantity: item.quantity || 1
        }

        switch (this.invoice.concession_type) {
          case 'Insurance':
            data.price = product_item.price_insurance
            break;
          case 'Resident':
            data.price = product_item.price_th
            break;
          default:
            data.price = product_item.price_inter
            break;
        }

        this.invoice.product_items.push(data)
      }

      this.selectedPackage = null
      this.$bvModal.hide('new-patient-invoice-package-modal')
    },
    async saveInvoice() {
      let loader = this.$loading.show({});
      let isNewInvoice = !this.invoice.invoice_id

      if(!this.invoice.patient_name) {
        loader.hide()
        this.$alert('Please select patient')
        return
      }
      if(!this.invoice.type) {
        loader.hide()
        this.$alert('Please select type OPD/IPD')
        return
      }
      if(!this.invoice.VN || !this.invoice.visit_date){
        loader.hide()
        this.$alert('No visit information, please select visit(VN)', null, 'error')
        return
      }

      if(isNewInvoice) {
        this.invoice.history = [{
          type: 'create',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        }]
      }else{
        this.invoice.history.push({
          type: 'edit',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        })
      }

      try {
        let sod = this.$moment().startOf('day').toDate()
        let eod = this.$moment().endOf('day').toDate()
        let latestInvoice = await db.collection('Invoice')
          .where('issue_date', '<', eod)
          .where('issue_date', '>=', sod)
          .get()
        let invoicesToday = latestInvoice.docs.length
        console.log('current invoice today', invoicesToday)
        console.log(this.invoice)

        const randomset = this.generateRandomString()
        let invoice_id
        if (this.invoice.invoice_id) {
          invoice_id = this.invoice.invoice_id
        } else {
          invoice_id = `${String(this.$store.state.branch).padStart(2, '0')}-${this.$moment().format('YYYY')}-${String(invoicesToday + 1).padStart(4, '0')}-${this.$moment().format('MM-DD')}${randomset}`
        }
        console.log({ invoice_id })
        this.invoice.invoice_id = invoice_id
        let total_invoiced = 0
        for (let item of this.invoice.billable_items) {
          item.quantity = Number(item.quantity)
          item.price = Number(item.price)
          item.discount = Number(item.discount)
          if(Number(item.discount)<0 || Number(item.discount)>100){
            this.$alert('discount should be 0-100%')
            loader.hide()
            return
          }
          if(Number(item.price)<0){
            this.$alert('price can not below 0')
            loader.hide()
            return
          }
          if (Number(item.quantity) < 0) {
            this.$alert('quantity can not below 0')
            loader.hide()
            return
          }
          total_invoiced += (Number(item.price) - Number(Number(item.price) * item.discount / 100)) * Number(item.quantity)
        }
        for (let item of this.invoice.product_items) {
          item.quantity = Number(item.quantity)
          item.price = Number(item.price)
          item.discount = Number(item.discount)
          if (Number(item.discount) < 0 || Number(item.discount) > 100) {
            this.$alert('discount should be 0-100%')
            loader.hide()
            return
          }
          if (Number(item.price) < 0) {
            this.$alert('price can not below 0')
            loader.hide()
            return
          }
          if (Number(item.quantity) < 0) {
            this.$alert('quantity can not below 0')
            loader.hide()
            return
          }
          total_invoiced += (Number(item.price) - Number(Number(item.price) * item.discount / 100)) * Number(item.quantity)
        }
        this.invoice.total_invoiced = total_invoiced
        if (!this.invoice.payment_applied) this.invoice.payment_applied = 0

        let invoice = {
          ...this.invoice
        }

        invoice.issue_date = this.$moment(invoice.issue_date, 'DD/MM/YYYY HH:mm').toDate()
        invoice.visit_date = this.$moment(invoice.visit_date, 'DD/MM/YYYY HH:mm').toDate()

        await db.collection('Invoice').doc(invoice_id).set(invoice)
        loader.hide()
        this.$alert('Invoice saved successfully', null, 'success')
        this.$emit('refetchInvoices')
        // this.$bvModal.hide('new-invoice-modal')
      } catch (error) {
        console.error(error)
        this.$alert(`error ${error}`, null, "error");
        loader.hide()
      }
    },
    async deleteInvoice(invoiceId) {
      this.$confirm('Do you want to delete this invoice?').then(async () => {
        try {
          
          await db.collection('Invoice').doc(invoiceId).delete()
  
          let paymentRefs = await db.collection('Payment').where('invoice_id', '==', invoiceId).get()
          let deletedPaymentsText = ``
          for(let payment of paymentRefs.docs) {
            await payment.ref.delete()
            deletedPaymentsText += `payment: ${payment.id} was deleted\n`
          }
  
          this.$alert(`invoice: ${invoiceId} was deleted\n`+deletedPaymentsText, null, 'success')
        } catch (error) {
          this.$alert(error, null , 'error')
        }

        this.$bvModal.hide('new-patient-invoice-modal')
        this.$emit('refetchInvoices')
      })
    },
    generateRandomString() {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const first_digit = alphabet[Math.floor(Math.random() * alphabet.length)];
      const second_digit = alphabet[Math.floor(Math.random() * alphabet.length)];
      const third_digit = Math.floor(Math.random() * 10);

      return `${first_digit}${second_digit}${third_digit}`
    },
    showNewPayment(invoice) {
      this.$emit('showNewPayment', invoice)
    }
  },
  computed: {
    patientList() {
      return this.patients.map(p => {
        return {
          name: `${p.first_name || ''} ${p.last_name || ''}`,
          HN: p.HN
        }
      })
    },
    preparedDiscount() {
      let data = []
      for(let i = 0; i<= 30; i+= 0.5) {
        data.push({
          name: `${i} %`, id: i
        })
      }
      return data
    },
    prepared_products() {
      return this.products.map(i => {
        return {
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null,
        }
      })
    },
    prepared_billable_items() {
      return this.billable_items.map(i => {
        return {
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null
        }
      })
    },
    prepared_packages() {
      return this.packages.map(i => {
        return {
          package_name: i.package_name,
          package_code: i.package_code,
          billable_items: i.billable_items,
          product_items: i.product_items
        }
      })
    },
    totalDiscount() {
      let sum_product = _.sum(this.invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      let sum_billable_items = _.sum(this.invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      const res = Math.ceil(sum_product + sum_billable_items)
      return res
    },
    totalAmount() {
      let invoice = this.invoice
      let sum = _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) - (Number(item.price) * Number(item.discount) / 100))
        } else {
          return 0
        }
      })) + _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) - (Number(item.price) * Number(item.discount) / 100))
        } else {
          return 0
        }
      }))
      const res = Math.ceil(sum)
      return res
    },
  }
}
</script>