<template>
    <div>
      <div class="container-fluid">
        <div class="card shadow">
          <div class="card-body">
            <div class="d-flex mt-2 mb-2">
              <div class="mr-auto">
                <h4 class="text-primary">Expense Dashboard</h4>
              </div>
              <div class="timer">
                <h5 class="text-primary text-center">
                  {{ timer | moment("dddd") }}
                </h5>
                <h1 class="text-primary text-center">
                  {{ timer | moment("HH:mm A") }}
                </h1>
                <div class="text-primary text-center">
                  {{ timer | moment("DD/MMMM/YYYY") }}
                </div>
              </div>
            </div>
  
            <hr />
            <b-nav class="mb-2" tabs>
              <b-nav-item :active="$route.matched.some(route => route.path.includes(menu.path_name))" v-for="menu in menus" :key="menu.path_name" :to="`/expense/${menu.path_name}`">
                {{ menu.name }}
              </b-nav-item>
            </b-nav>
  
            <hr />
            <date-filter v-model="filter"></date-filter>
            <hr />
  
            <router-view :filter="filter"></router-view>
  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    props: ["patient", "nursing"],
    data() {
      return {
        menus: [
          { name: 'EXPENSE DASHBOARD', path_name: 'expense-dashboard' },
          { name: 'EXPENSE SUMMARY', path_name: 'expense-summary' },
        ],
        timer: new Date(),
        filter: {
          from: this.$moment().format('DD/MM/YYYY'),
          to: this.$moment().format('DD/MM/YYYY'),
          period: { name: 'Day', from: this.$moment().add(-1, 'day').format('DD/MM/YYYY'), to: this.$moment().format('DD/MM/YYYY'),
          income: 'Daily',
          total: 'TODAY\'S' },
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .timer {
    border-radius: 0.25em;
    padding: 15px;
    min-width: 300px;
    height: 150px;
    box-shadow: 2px 2px 10px #bfbfbf;
  }
  
  .card {
    min-height: 800px;
  }
  </style>