<template>
  <div class="card m-4">
    <div class="card-body">
      <div class="container">
        <h3 class="text-primary">Create new item</h3>
        <hr>
        <form @submit.prevent="save">
          <div class="form-group">
            <label>ID</label>
            <input type="text" class="form-control" v-model="medicine.id" required>
          </div>
          <div class="form-group">
            <label>Brand name</label>
            <input type="text" class="form-control" v-model="medicine.brand_name">
          </div>
          <div class="form-group">
            <label>Generic name</label>
            <input type="text" class="form-control" v-model="medicine.generic_name">
          </div>
          <div class="form-group">
            <label>Barcode</label>
            <input type="text" class="form-control" v-model="medicine.barcode">
          </div>
          <button class="btn btn-block btn-primary mt-2" type="submit" @click="save">
            <i class="fas fa-save"></i> Create new item
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      medicine: {
        id: null,
        brand_name: null,
        generic_name: null,
        barcode: null,
        audit_logs: [],
      }
    }
  },
  methods: {
    async save() {
      let loader = this.$loading.show({})
      let medicine = {
        ...this.medicine
      }

      medicine.audit_logs.push({
        date: new Date(),
        user: this.$store.getters.user,
        message: 'created item'
      })
      // await db.collection('Medicine').doc(this.medicine.id).set(medicine)

      loader.hide()
    }
  }
}
</script>