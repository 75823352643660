<template>
  <div>
    <vue-good-table class="mt-3" 
      :pagination-options="{
        enabled: true,
        perPageDropdown: [30, 40, 50, 100],
        dropdownAllowAll: false,
      }"
      :columns="columns" 
      :rows="filteredItems" 
      :fixed-header="true" 
      :line-numbers="true" 
      :sort-options="{
        enabled: false
      }" 
      :group-options="{
        enabled: true
      }" 
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'details'">
          <div class="text-dark">
            <b>{{ props.row.patient.first_name }} {{ props.row.patient.last_name }}</b>
          </div>
          <div class="text-muted">
            <i>HN: {{ props.row.patient.HN }}</i>
          </div>
        </div>
        <div v-else-if="props.column.label == 'Visit Type'">
          <span class="new-patient" v-if="props.row.visit_type === 'New Patient'">
            NEW PATIENT
          </span>
          <span class="follow-up" v-if="props.row.visit_type === 'Follow up'">
            FOLLOW UP
          </span>
          <span class="old-data" v-if="!props.row.visit_type">
            OLD DATA
          </span>

        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { db } from "../../db";
import { searchPatient } from '../../meili'
import _ from "lodash";
import moment from "moment";
export default {
  props: ['dateStart', 'dateEnd', 'keyword', 'mode', 'branch'],
  data() {
    return {
      columns: [
        {
          label: "Visit Time",
          field: this.time,
        },
        {
          label: "Patient Details",
          field: "details",
        },
        {
          label: "Visit Type",
          field: "visit_type",
        },
        {
          label: "Branch",
          field: "visit_branch",
        },
        {
          label: "ESI & Diagnosis",
          field: "cc_dx",
        },
        {
          label: "Drugs & Other Allergy",
          field: this.getAllergyList,
        },
        {
          label: "Insurance",
          field: this.getInsurance
        },
        {
          label: "Note",
          field: "note"
        }
      ],
      visits: [],
    };
  },
  methods: {
    async initData() {
      let loader = this.$loading.show({})
      this.$emit('onLoading', true);
      let visits = [];
      let startDate = this.$moment(this.dateStart, "DD/MM/YYYY").startOf('day').toDate();
      let endDate = this.$moment(this.dateEnd, "DD/MM/YYYY").endOf('day').toDate();
      console.log("🚀 ~ initData ~ startDate:", startDate)
      console.log("🚀 ~ initData ~ endDate:", endDate)
      let branch_selected = String(this.branch)
      let patientsRef = await db.collection('Patient')
        .where('visit_date', '>=', startDate)
        .where('visit_date', '<=', endDate)
        .orderBy('visit_date', 'desc')
        .limit(30)
        .get();
      let visitRef
      for (let p of patientsRef.docs) {
        if (branch_selected !== 'all') {
          visitRef = await db.collection('Visit')
            .where('patient', '==', db.collection('Patient').doc(p.id))
            .where('visit_date', '>=', startDate)
            .where('visit_date', '<=', endDate)
            .where('branch', '==', db.collection('Branch').doc(branch_selected))
            .get();
        } else {
          visitRef = await db.collection('Visit')
            .where('patient', '==', db.collection('Patient').doc(p.id))
            .where('visit_date', '>=', startDate)
            .where('visit_date', '<=', endDate)
            .get();
        }
        if (visitRef.docs && visitRef.docs.length > 0) {
          for (let v of visitRef.docs) {
            v.data().branch
            console.log("🚀 ~ initData ~ v.data().branch:", v.data().branch.id)
            let data = {
              visit_type: v.data().visit_type,
              visit_date: v.data().visit_date,
              visit_branch: v.data().branch?.id ? this.$store.state.branches.find(item => item.id == v.data().branch.id).name_en : '-',
              patient: await this.getPatientRowData(p.id)
            };
            visits.push(data);
          }
        }
      }

      this.visits = this._.orderBy(visits, 'visit_date', 'desc');
      this.$emit('onLoaded', true);

      loader.hide()
    },
    async getPatientRowData(HN) {
      let p = await db.collection('Patient').doc(HN).get()
      let patient = {
        HN: HN,
        first_name: p.data().first_name,
        last_name: p.data().last_name,
        note: p.data().note,
        allergies: [],
        other_allergies: []
      }

      if (p.data().allergies?.length > 0) {
        for (let a of p.data().allergies) {
          let aRef = await db.collection('Allergy').doc(a.id).get()
          patient.allergies.push({
            name: aRef.data().name
          })
        }
      }

      if (p.data().other_allergies?.length > 0) {
        for (let a of p.data().other_allergies) {
          let aRef = await db.collection('Allergy').doc(a.id).get()
          patient.other_allergies.push({
            name: aRef.data().name
          })
        }
      }

      if (p.data().has_travel_insurance && p.data().travel_insurance) {
        let insuranceRef = await db.collection('Insurance').doc(p.data().travel_insurance.id).get()
        patient.has_travel_insurance = true
        patient.travel_insurance = { name_en: insuranceRef.data().name_en }
      }

      return patient
    },
    onRowClick(params) {
      let HN = params.row.patient.HN;
      const routeData = this.$router.resolve(`/patient/${HN}/visit`);
      window.open(routeData.href, '_blank');
    },
    getAllergyList(rowObj) {
      let allergies = rowObj.patient.allergies;
      let other_allergies = rowObj.patient.other_allergies;
      if (allergies && other_allergies) {
        let allergies_string = allergies
          .map(item => {
            return item.name;
          })
          .join(", ");
        let other_allergies_string = other_allergies
          .map(item => {
            return item.name;
          })
          .join(", ");
        return [allergies_string, other_allergies_string].join(", ");
      } else {
        return "-";
      }
    },
    getInsurance(item) {
      if (item.patient.travel_insurance) return item.patient.travel_insurance.name_en;
      else return item.patient.has_travel_insurance;
    },
    time(rowObj) {
      if (rowObj.visit_date) {
        try {
          return this.$moment(rowObj.visit_date?.toDate()).format("HH:mm");
        } catch (error) {
          return '-'
        }
      } else {
        return '-'
      }
    },
    getEsiColor(esi) {
      switch (esi) {
        case 1:
          return "#CB0000";
        case 2:
          return "#EA9A99";
        case 3:
          return "#F1C331";
        case 4:
          return "#629B49";
        case 5:
          return "#FFFFFF";
      }
    },
    async handleKeywordSearch() {
      if (this.keyword.length < 3) {
        this.$alert('Minimum keyword length: 3')
        return
      }
      
      let loader = this.$loading.show({})
      this.$emit('onLoading', true)
      let result = await searchPatient(this.keyword)

      if (result.hits.length > 0) {
        let visits = []
        for (let hit of result.hits) {
          let visitRef = await db.collection('Visit').where('patient', '==', db.collection('Patient').doc(hit.HN)).get()
          if (visitRef.docs.length > 0) {
            for (let v of visitRef.docs) {
              let data = {
                visit_type: v.data().visit_type,
                visit_date: v.data().visit_date,
                patient: await this.getPatientRowData(hit.HN)
              }
              visits.push(data)
            }
          }
        }

        this.visits = this._.orderBy(visits, 'visit_date', 'desc')
      }
      this.$emit('onLoaded', true)
      loader.hide()
    }
  },
  computed: {
    filteredItems() {
      let items = _.clone(this.visits);
      console.log("🚀 ~ filteredItems ~ items:", items)

      let itemGroups = [];
      let groupedItems = this._.groupBy(items, item => {
        return this.$moment(item.visit_date.toDate()).format("DD MMM YYYY");
      });

      for (let group in groupedItems) {
        let groupLabel = {
          mode: "span",
          label: group,
          html: false,
          children: []
        };

        for (let item of groupedItems[group]) {
          groupLabel.children.push(item);
        }

        itemGroups.push(groupLabel);
      }
      console.log("🚀 ~ filteredItems ~ itemGroups:", itemGroups)
      return itemGroups;
    }
  }
};
</script>

<style scoped>
.follow-up {
  background-color: #5dddc8;
  color: #fff;
  padding: 5px;
  border-radius: 0.5em;
}

.new-patient {
  background-color: #4786FB;
  color: #fff;
  padding: 5px;
  border-radius: 0.5em;
}

.old-data {
  background-color: #ADADAD;
  color: #fff;
  padding: 5px;
  border-radius: 0.5em;
}
</style>