import PrintLayout from "../pages/Print/PrintLayout.vue";
import MedCert from "../pages/Print/MedCert.vue";
import Register from "../pages/Print/PrintRegister.vue";
import Receipt from "../pages/Print/PrintReceipt.vue";
import Invoice from "../pages/Print/PrintInvoice.vue";
import InvoicesSummary from "../pages/Print/PrintInvoicesSummary.vue";
import PaymentsSummary from "../pages/Print/PrintPaymentsSummary.vue";
import InventoryReport from "../pages/Print/PrintInventoryReport.vue";
export default [
  {
    path: "/print",
    name: "Print",
    component: PrintLayout,
    meta: {
      print: true
    },
    children: [
      {
        path: "medcert/:visitId",
        name: "Print Medical Certificate",
        component: MedCert,
        meta: {
          print: true
        }
      },
      {
        path: "register-form/:id",
        name: "Print Register Form",
        component: Register,
        meta: {
          print: true
        }
      },
      {
        path: "receipt/:receiptId",
        name: "Print Receipt Form",
        component: Receipt,
        meta: {
          print: true
        }
      },,
      {
        path: "invoice/:invoiceId",
        name: "Print Invoice Form",
        component: Invoice,
        meta: {
          print: true
        }
      },
      {
        path: "invoices",
        name: "Print Invoice Summary",
        component: InvoicesSummary,
        meta: {
          print: true
        }
      },
      {
        path: "payments",
        name: "Print Payment Summary",
        component: PaymentsSummary,
        meta: {
          print: true
        }
      },
      {
        path: "inventory-report/",
        name: "Print Inventory",
        component: InventoryReport,
        meta: {
          print: true
        }
      }
    ]
  }
];
