<template>
  <div class="sheet p-4">
    <div class="container-fluid">
      <payment-table :data="payments" />
      <p class="text-grey mt-2 text-right" v-if="start && end">{{ $route.query.start }} to {{ $route.query.end }}</p>
    </div>
  </div>
</template>

<script>
import { db } from '../../db'
import { capitalizeFirstChar } from '../../helper'

export default {
  data() {
    return {
      payments: [],
      start: this.$route.query.start,
      end: this.$route.query.end,
      patientId: this.$route.query.patientId,
    }
  },
  mounted() {
    this.fetchPayments()
  },
  methods: {
    async fetchPayments() {
      const loader = this.$loading.show({})
      const payments = []

      const paymentsCollection = this.patientId ?
        await db.collection('Payment')
          .where('HN', '==', this.patientId)
          .get()
        :
        await db.collection('Payment')
          .where('issue_date', '>', this.$moment(this.start, 'DD/MM/YYYY').startOf('day').toDate())
          .where('issue_date', '<', this.$moment(this.end, 'DD/MM/YYYY').endOf('day').toDate())
          .get()

      paymentsCollection.docs.forEach(paymentCollection => {
        const payment = paymentCollection.data()
        const preparedPayments = []

        if (payment.cash > 0) {
          preparedPayments.push({ name: 'Cash', amount: payment.cash })
        }
        if (payment.credit > 0) {
          preparedPayments.push({
            name: `Credit ${payment.credit_bank_name ? capitalizeFirstChar(payment.credit_bank_name) : ''}`,
            amount: payment.credit
          })
        }
        if (payment.insurance > 0) {
          preparedPayments.push({ name: 'Insurance', amount: payment.insurance })
        }
        if (payment.internet_banking > 0) {
          preparedPayments.push({ name: 'Internet banking', amount: payment.internet_banking })
        }
        if (payment.other > 0) {
          preparedPayments.push({
            name: payment.other_name ? capitalizeFirstChar(payment.other_name) : 'Other',
            amount: payment.other
          })
        }
        payment.total_amount = [payment.cash, payment.credit, payment.other, payment.insurance, payment.internet_banking]
          .reduce((sum, value) => sum + Number(value || 0), 0)

        payment.preparedPayments = preparedPayments
        payments.push(payment)
      })

      const paymentsByDate = _.groupBy(payments, payment => {
        return this.$moment(payment.issue_date.toDate()).format("DD MMM YYYY")
      })

      this.payments = Object.entries(paymentsByDate).map(([date, groupedPayments]) => ({
        mode: 'span',
        label: date,
        html: false,
        children: groupedPayments
      }))

      loader.hide()
    },
  }
}
</script>

<style type="text/css" media="print">
@page { size: landscape !important }
</style>