<template>
  <div>
    <b-nav class="mb-2" tabs v-if="!$route.meta.print">
      <b-nav-item :active="(currentMenu === menu.name)" v-for="(menu, index) in menus" :key="index" @click="(currentMenu = menu.name)">{{ menu.name }}</b-nav-item>
    </b-nav>

    <div v-if="currentMenu === 'Invoice'">
      <div class="d-flex">
        <h3 class="text-primary">Invoice</h3>
        <button class="btn btn-light ml-auto" @click="$bvModal.show('print-finance-modal')" tabs v-if="!$route.meta.print">
          <i class="fas fa-print"></i> Print Invoice Summary
        </button>
        <button class="btn btn-primary ml-1" @click="showNewInvoice()" tabs v-if="!$route.meta.print">
          <i class="fas fa-plus"></i> New Invoice
        </button>
      </div>
      <div class="input-group mt-2 mb-2" tabs v-if="!$route.meta.print">
        <input
          type="text"
          class="form-control"
          focus
          placeholder="search by ID, patient's name"
          v-model="keyword"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
      <vue-good-table
        :columns="invoiceColumns"
        :rows="groupedInvoices"
        :use-dynamic-row-height="true"
        :calculate-width-by-content="calculateWidthByContent"
        :sort-options="{
          enabled: false,
        }"
        :group-options="{
            enabled: true
          }"
        :pagination-options="{
          enabled: !this.$route.meta.print,
          perPageDropdown: [30, 40, 50, 100, 200],
          dropdownAllowAll: false,
        }">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.label == 'Date Time'">
            {{ props.formattedRow[props.column.field].toDate() | moment('HH:mm') }}
          </div>
          <div v-else-if="props.column.label == 'Visit Date'">
            <div v-if="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field].toDate() | moment('DD/MM/YYYY HH:mm') }}</div>
          </div>
          <div v-else-if="props.column.label == 'Patient Name'">
            <a target="_blank" :href="`/patient/${props.row['HN']}`">{{props.formattedRow[props.column.field]}}</a>
          </div>
          <div v-else-if="props.column.label == 'Payment Type'">
            <div v-for="(payment, index) of props.row['payments']" :key="index">
              <a href="#" @click="showPayment(payment.payment_id)" >{{index+1}}. {{ payment.cash ?'cash':'' }} {{ payment.credit ? 'credit' : '' }} {{ payment.insurance ? 'insurance' : '' }} {{ payment.internet_banking ? 'internet banking' : '' }} {{ payment.other ? 'other' : '' }} </a>
            </div>
          </div>
          <div v-else-if="props.column.label == 'Payment Amount'">
            <div v-for="(payment, index) of props.row['payments']" :key="index">
              <a href="#" @click="showPayment(payment.payment_id)" >{{index+1}}. {{ Math.round((Number(payment.cash)+Number(payment.credit)+Number(payment.internet_banking)+Number(payment.other)+Number(payment.insurance))).toLocaleString()  }} </a>
            </div>
          </div>
          <div v-else-if="props.column.label == 'Invoice#'">
            <div class="d-flex">
              <a href="#" @click="showInvoice(props.row.invoice_id)" class="mt-2">{{ props.formattedRow[props.column.field] }}</a>
            </div>
          </div>
          <div v-else-if="props.column.label == 'Discount'">
            {{ Math.round(getTotalDiscount(props.row)).toLocaleString() }}
          </div>
          <div v-else-if="props.column.label == 'Print'">
            <button class="btn btn-light text-white" >
              <a target="_blank" :href="`/print/invoice/${props.formattedRow['invoice_id']}`"><i class="fas fa-print"></i></a>
            </button>
          </div>
          <div v-else-if="props.column.label == 'Total Amount'">
            {{ Math.round(props.formattedRow[props.column.field]).toLocaleString() }}
          </div>
          <div v-else-if="props.column.label == 'Status'" class="text-center">
            <span class="badge" :class="{
                'badge-success': props.row['status'] === 'paid',
                'badge-warning': props.row['status'] === 'outstanding',
                'badge-warning': props.row['status'] === 'gop',
                'badge-danger': props.row['status'] === 'waiting for payment',
                'badge-danger': props.row['status'] === 'denied',
                'badge-danger': props.row['status'] === 'unpaid',
                'badge-dark': props.row['status'] === 'cancelled',
              }">{{ props.row['status'].toUpperCase() }}</span>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <div v-if="currentMenu === 'Payment'">
      <div class="d-flex">
        <h3 class="text-primary">Payment</h3>
        <button class="btn btn-light ml-auto" @click="$bvModal.show('print-finance-modal')" tabs v-if="!$route.meta.print">
          <i class="fas fa-print"></i> Print Payment Summary
        </button>
        <!-- <button class="btn btn-primary ml-auto" @click="showNewPayment">
          <i class="fas fa-plus"></i> New Payment
        </button> -->
      </div>
      <div class="input-group mt-2 mb-2" v-if="!$route.meta.print">
        <input
          type="text"
          class="form-control"
          focus
          placeholder="search by ID, patient's name"
          v-model="keyword"
        />
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
    
      <vue-good-table
        :columns="paymentColumns"
        :rows="groupedPayments"
        :use-dynamic-row-height="true"
        :calculate-width-by-content="calculateWidthByContent"
        :sort-options="{
          enabled: false,
        }"
        :group-options="{
          enabled: true
        }"
        :pagination-options="{
          enabled: !this.$route.meta.print,
          enabled: true,
          perPageDropdown: [30, 40, 50, 100, 200],
          dropdownAllowAll: false,
        }">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.label == 'Datetime'">
            {{ props.formattedRow[props.column.field].toDate() | moment('HH:mm') }}
          </div>
          <div v-else-if="props.column.label == 'Payment#'">
            <div class="d-flex">
              <a href="#" @click="showPayment(props.row.payment_id)" class="mt-2">{{ props.formattedRow[props.column.field] }}</a>
            </div>
          </div>
          <div v-else-if="props.column.label == 'Patient Name'">
            <a target="_blank" :href="`/patient/${props.row['HN']}`">{{ props.formattedRow[props.column.field] }}</a>
          </div>
          <div v-else-if="props.column.label == 'Payment Type'">
            <div :key="index" v-for="(p, index) in props.row.preparedPayments">
              <a href="#" @click="showPayment(props.row.payment_id)" >
                {{ index+1 }}. {{ capitalizeFirstChar(p.name) }}
              </a>
            </div>
          </div>
          <div v-else-if="props.column.label == 'Payment Amount'">
            <div :key="index" v-for="(p, index) in props.row.preparedPayments">
              <a href="#" @click="showPayment(props.row.payment_id)" >
                {{ index+1 }}. {{ p.amount.toLocaleString() }}
              </a>
            </div>
          </div>
          <div v-else-if="props.column.label == 'Total Amount'">
            <a href="#" @click="showPayment(props.row.payment_id)" >{{ (Number(props.row.cash) + Number(props.row.credit) + Number(props.row.internet_banking) + Number(props.row.other) + Number(props.row.insurance)).toLocaleString() }} </a>
          </div>
          <div v-else-if="props.column.label == 'Print'">
            <button class="btn btn-light text-white" >
              <a target="_blank" :href="`/print/receipt/${props.formattedRow['payment_id']}`"><i class="fas fa-print"></i></a>
            </button>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>

    <b-modal id="new-invoice-modal" hide-footer size="xl" header-bg-variant="gray" :title="`${invoice.invoice_id ? 'Invoice #' + invoice.invoice_id : 'New Invoice'}`" >
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-1">
            <div class="text-primary">From</div>
          </div>
          <div class="col-3">
            <b>WORLDMED CENTER</b>
            <p>125/131-133, M.7 Phi Phi Island, T.Aonang A.MuangKrabi Krabi, 81210 Thailand</p>
            <div class="mt-2">
              <b>WORLDMED CENTER Co., Ltd.</b>
              <div>0835557004354</div>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Patient:</label>
              <object-select
                class="ml-auto"
                v-model="invoice.HN" 
                @input="handlePatientNameSelect"
                :options="preparedPatients.map( p => {
                  return {
                    id: p.HN,
                    name: p.name
                  }
                })"
              />
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Billed to:</label>
              <textarea type="text" class="form-control" v-model="invoice.billed_to"/>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Concession type:</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.concession_type"
                :options="['Resident', 'Foreigner', 'Insurance']"
                @input="handleConcessionChanged"
              />
            </div>
            <div class="d-flex mb-2" v-if="invoice.concession_type === 'Insurance'">
              <label class="text-primary">Insurance: </label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.insurance_name"
                :options="[
                  'Other',
                  ..._.sortBy($store.state.insurances.map(c => {
                    return c.name_en
                  }))
                ]"
              />
              
            </div>
            <div class="d-flex mb-2" v-if="invoice.insurance_name === 'Other'" >
              <label class="text-primary">Other insurance: </label>
              <input 
                type="text" 
                class="form-control ml-2" 
                v-model="invoice.insurance_name_other"
                placeholder="Other Insurance Name">
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Status: </label>
              <object-select
                class="ml-2"
                v-model="invoice.status"
                :options="[
                  { id: 'unpaid', name: 'Unpaid' },
                  { id: 'waiting for payment', name: 'Waiting for Payment' },
                  { id: 'denied', name: 'Denied' },
                  { id: 'outstanding', name: 'Outstanding' },
                  { id: 'gop', name: 'GOP' },
                  { id: 'paid', name: 'Paid' },
                  { id: 'canceled', name: 'Canceled' },
                ]"
              />
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Type:</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.type"
                :options="['OPD', 'IPD']"
              />
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-1">Discount:</label>
              <object-select
                class="ml-2"
                v-model="invoice.discount"
                :options="preparedDiscount"
              />
              <button class="btn btn-primary ml-2" @click="applyDiscount(invoice.discount)">Apply</button>
            </div>
          </div>

          <div class="col-4">
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Issue Date</label>
              <MazPicker v-model="invoice.issue_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`" placeholder="Issue Date" />
            </div>
            <div class="d-flex mb-2" v-if="invoice.invoice_id">
              <label class="text-primary mr-auto">Invoice#</label>
              <div class="text-right">{{ invoice.invoice_id }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">HN</label>
              <div class="text-right">{{ invoice.HN }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Visit Date</label>
              <div>
                <object-select
                  class="ml-2"
                  v-model="invoice.VN"
                  mode="required"
                  :options="visits.map(item => {
                    return {
                      id: item.VN,
                      name: `${$moment(item.visit_date).format('DD/MM/YYYY HH:mm')}`
                    }
                  })"
                />
              </div>
            </div>
            <!-- <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Visit Date</label>
              <MazPicker v-model="invoice.visit_date" :format="`DD/MM/YYYY HH:mm`" :formatted="`DD/MM/YYYY HH:mm`" placeholder="Visit Date" />
            </div> -->
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Patient</label>
              <div class="text-right">{{ invoice.patient_name }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Practitioner</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="invoice.practitioner"
                :options="doctors.map(d => {
                  return d.displayName
                })"
              />
            </div>
          </div>
        </div>
      </div>

      <hr>

    
      <div class="d-flex p-2 bg-light">
        <h5 class="text-primary">BILLABLE ITEMS</h5>
      </div>
      <div class="container-fluid">
        <draggable v-model="invoice.billable_items" group="billable_items" @start="drag = true" @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in invoice.billable_items" :key="index">
            <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div>
            <div class="col-4">
              <label class="text-primary">Item</label>
              <object-select
                class="ml-auto"
                v-model="item.item_code"
                :options="prepared_billable_items.map(i => {
                  return { id: i.item_code, name: i.item_name }
                })"
                @input="handleBillableItemChanged(index)"
              />
            </div>
            <div class="col-1">
              <label class="text-primary">Quantity</label>
              <input type="number" class="form-control" v-model="item.quantity">
            </div>
            <div class="col-2">
              <label class="text-primary">Unit Price</label>
              <input-number v-model="item.price"></input-number>
            </div>
            <div class="col-2">
              <label class="text-primary">Discount</label>
              <b-input-group append="%">
                <b-form-input v-model="item.discount" type="number"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-1 text-right">
              <label class="text-primary ml-auto">Total</label>
              <div v-if="item.quantity && item.price">{{ Math.ceil(((item.price - (item.price * item.discount / 100)) * item.quantity)).toLocaleString() }}</div>
            </div>
            <div class="col-1">
              <button class="btn btn-light" @click="invoice.billable_items.splice(index, 1)"><i class="fas fa-trash"></i></button>
            </div>
            <hr>
          </div>
        </draggable>
      </div>

      <div class="d-flex p-2 bg-light">
        <h5 class="text-primary">PRODUCTS ITEMS</h5>
      </div>
      <div class="container-fluid">
        <draggable v-model="invoice.product_items" group="invoice.product_items" @start="drag = true" @end="drag = false">
          <div class="row mb-2" v-for="(item, index) in invoice.product_items" :key="index">
            <div class="col-1">
              <div class="m-4 p-2">
                <i class="fas fa-bars" style="color: grey"></i>
              </div>
            </div>
            <div class="col-4">
              <label class="text-primary">Item</label>
              <object-select
                class="ml-auto"
                v-model="item.item_code"
                :options="prepared_products.map(i => {
                  return { id: i.item_code, name: i.item_name }
                })"
                @input="handleProductChanged(index)"
              />
            </div>
            <div class="col-1">
              <label class="text-primary">Quantity</label>
              <input type="number" class="form-control" v-model="item.quantity">
            </div>
            <div class="col-2">
              <label class="text-primary">Unit Price</label>
              <input type="number" class="form-control" v-model="item.price">
            </div>
            <div class="col-2">
              <label class="text-primary">Discount</label>
              <b-input-group append="%">
                <b-form-input v-model="item.discount" type="number"></b-form-input>
              </b-input-group>
            </div>
            <div class="col-1 text-right">
              <label class="text-primary ml-auto">Total</label>
              <div v-if="item.quantity && item.price">{{ Math.ceil(((item.price - (item.price * item.discount / 100)) * item.quantity)).toLocaleString() }}</div>
            </div>
            <div class="col-1">
              <button class="btn btn-light" @click="invoice.product_items.splice(index, 1)"><i class="fas fa-trash"></i></button>
            </div>
            <hr>
          </div>
        </draggable>
      
      </div>
    
      <div class="d-flex p-2 bg-gray">
        <button class="btn btn-primary mr-2" @click="addBillableItem">
          <i class="fas fa-plus"></i> Add Billable Item
        </button>
        <button class="btn btn-primary mr-2" @click="addProduct">
          <i class="fas fa-plus"></i> Add Product
        </button>
        <button class="btn btn-primary mr-2" @click="showPackage">
          <i class="fas fa-plus"></i> Add Package
        </button>
      </div>

      <div class="container-fluid mt-4">
        <div class="row">
          <div class="col-5">
            <div class="d-flex mb-2">
              <b class="mr-auto">Total Discount</b>
              <div>{{ totalDiscount.toLocaleString() }}
              </div>
            </div>
            <div class="d-flex mb-2">
              <b class="mr-auto">Total Amount</b>
              <div>{{ totalAmount.toLocaleString() }}</div>
            </div>
          </div>
          <div class="col-7">
            <label class="text-primary">Note:</label>
            <textarea class="form-control" v-model="invoice.note"/>
          </div>
        </div>

        <label class="text-primary mt-4" v-if="invoice.history">History</label>
        <div class="row mt-2" v-if="invoice.history && invoice.history.length>0">
          <div class="col-12 text-grey" v-for="(item, index) of invoice.history" :key="index">
            [{{ item.datetime.toDate() | moment('DD/MM/YYYY HH:mm') }}] User: <span class="text-primary">{{item.user.displayName}} ({{ item.user.uid }})</span> Action:<span class="text-primary">{{ item.type }}</span>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2 mb-2">
        <router-link v-if="invoice.invoice_id" :to="`/print/invoice/${invoice.invoice_id}`" target="_blank" class="btn btn-light"
          style="margin-left: 10px; width: 150px;">
          <i class="fas fa-print"></i> Print Invoice
        </router-link>
        <router-link v-if="invoice.invoice_id" :to="`/print/invoice/${invoice.invoice_id}?mode=short`" target="_blank" class="btn btn-light"
          style="margin-left: 10px; width: 150px;">
          <i class="fas fa-print"></i> Print Short Invoice
        </router-link>
        <button v-if="invoice.invoice_id && invoice.status!=='paid'" class="btn btn-primary ml-2" @click="showNewPayment(invoice)">
          <i class="fas fa-money-bill-wave"></i> New Payment
        </button>
        <button class="btn btn-success ml-auto" @click="saveInvoice"><i class="fas fa-save"></i> Save Invoice</button>
        <button v-if="invoice.invoice_id && this.$store.getters.permission['Invoice'] === 5" class="btn btn-danger ml-2" @click="deleteInvoice(invoice.invoice_id)"><i class="fas fa-trash"></i> Delete Invoice</button>
      </div>
    </b-modal>

    <b-modal id="new-payment-modal" hide-footer size="xl" header-bg-variant="gray" :title="`${payment.payment_id ? 'Payment #' + payment.payment_id : 'New Payment'}`">
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-1">
            <div class="text-primary">From</div>
          </div>
          <div class="col-3">
            <b>WORLDMED CENTER</b>
            <p>125/131-133, M.7 Phi Phi Island, T.Aonang A.MuangKrabi Krabi, 81210 Thailand</p>
            <div class="mt-2">
              <b>WORLDMED CENTER Co., Ltd.</b>
              <div>0835557004354</div>
            </div>
          </div>
          <div class="col-4">
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Patient:</label>
              <p>{{ payment.patient_name }}</p>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Billed to:</label>
              <p>{{ payment.billed_to }}</p>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary">Concession type:</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="payment.concession_type"
                :options="['Resident', 'Foreigner', 'Insurance']"
              />
            </div>
            <div class="d-flex mb-2" v-if="payment.concession_type === 'Insurance'">
              <label class="text-primary">Insurance </label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="payment.insurance_name"
                :options="[
                  'Other',
                  ..._.sortBy($store.state.insurances.map(c => {
                    return c.name_en
                  }))
                ]"
              />
            </div>
            <div class="d-flex mb-2" v-if="payment.insurance_name === 'Other'" >
              <label class="text-primary">Other insurance: </label>
              <input 
                type="text" class="form-control ml-2" 
                v-model="payment.insurance_name_other"
                placeholder="Insurance">
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Type:</label>
              <p>{{ payment.type }}</p>
            </div>
          </div>
  
          <div class="col-4">
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Issue Date</label>
              <div class="text-right" v-if="invoice.issue_date.toDate">{{ $moment(invoice.issue_date.toDate()).format('HH:mm DD MMM YYYY') }}</div>
              <div class="text-right" v-else>{{ $moment(invoice.issue_date).format('HH:mm DD MMM YYYY') }}</div>
            </div>
            <div class="d-flex mb-2" v-if="payment.payment_id">
              <label class="text-primary mr-auto">Payment#</label>
              <div class="text-right">{{ payment.payment_id }}</div>
            </div>
            <div class="d-flex mb-2" v-if="payment.invoice_id">
              <label class="text-primary mr-auto">Invoice#</label>
              <div class="text-right">{{ payment.invoice_id }}</div>
            </div>
            <div class="d-flex mb-2" v-if="payment.HN">
              <label class="text-primary mr-auto">HN</label>
              <div class="text-right">{{ payment.HN }}</div>
            </div>
            <div class="d-flex mb-2" v-if="payment.VN">
              <label class="text-primary mr-auto">Visit Number (VN)</label>
              <div class="text-right">{{ payment.VN }}</div>
            </div>
            <div class="d-flex mb-2" v-if="payment.patient_name">
              <label class="text-primary mr-auto">Patient</label>
              <div class="text-right">{{ payment.patient_name }}</div>
            </div>
            <div class="d-flex mb-2">
              <label class="text-primary mr-auto">Practitioner</label>
              <multiselect
                class="ml-2"
                selectLabel=""
                v-model="payment.practitioner"
                :options="doctors.map(d => {
                  return d.displayName
                })"
              />
            </div>
          </div>
        </div>
      </div>
  
      <hr>

      <div class="container-fluid">
        <div class="row">
          <div class="col-8">
            <h3 class="text-primary">Payment Source</h3>
            <div class="row mt-2">
              <div class="col-4">
                <addable-select 
                  v-model="payment.credit_bank_name"
                  :model="'CreditCard'"
                  :placeholder="`Select Bank`"
                  :options="creditcards.map(item => item.name)"/>
              </div>
              <div class="col-8">
                <input-number v-model="payment.credit"></input-number>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <label>CASH</label>
              </div>
              <div class="col-8">
                <input-number v-model="payment.cash"></input-number>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <label>INSURANCE</label>
              </div>
              <div class="col-8">
                <input-number v-model="payment.insurance"></input-number>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <label>INTERNET BANKING</label>
              </div>
              <div class="col-8">
                <input-number v-model="payment.internet_banking"></input-number>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-4">
                <multiselect
                  :style="`width: 100%`"
                  selectLabel=""
                  v-model="payment.other_name"
                  :options="[
                    'Other',
                    'Alipay',
                    'Apple Pay',
                    'LINE',
                    'Payoneer',
                    'Paypal',
                    'True wallet',
                    'Wechat',
                    'Wire',
                    'Promptpay'
                  ]"
                />
              </div>
              <div class="col-8">
                <input-number v-model="payment.other"></input-number>
              </div>
            </div>
            <div class="row mt-2" v-if="payment.credit > 0">
              <div class="col-4">
                Extra Charge
              </div>
              <div class="col-8">
                <select v-model="payment.extra_charge" class="form-control">
                  <option :value="0.03">3% BANK FEE FOR VISA/MASTER CARD</option>
                  <option :value="0.05">5% BANK FEE FOR AMERICAN EXPRESS/ UNION PAY</option>
                </select>
              </div>
            </div>
            <hr>
            <div class="d-flex mt-2">
              <label style="width: 120px;">Payment Total</label>
              <div class="ml-2">{{ (Number(payment.credit) + Number(payment.cash) + Number(payment.other) + Number(payment.insurance) + Number(payment.internet_banking)).toLocaleString() }}</div>
            </div>
          </div>
          <div class="col-4">
            <h3 class="text-primary">Payment Summary</h3>
            <div class="d-flex mt-2">
              <label style="width: 120px;">Total Invoiced</label>
              <div class="ml-auto">{{ total_invoiced.toLocaleString() }}</div>
            </div>
            <div class="d-flex mt-2">
              <label style="width: 120px;">Payment Applied</label>
              <div class="ml-auto">{{ payment_applied.toLocaleString() }}</div>
            </div>
            <div class="d-flex mt-2">
              <label style="width: 120px;">Outstanding Balance</label>
              <div class="ml-auto">{{ total_outstanding.toLocaleString() }}</div>
            </div>
            <div class="mt-2">
              <label class="text-primary">Note:</label>
              <textarea class="form-control" v-model="payment.note"/>
            </div>
          </div>
        </div>
      </div>

      <label class="text-primary mt-4" v-if="payment.history">History</label>
      <div class="row mt-2" v-if="payment.history && payment.history.length>0">
        <div class="col-12 text-grey" v-for="(item, index) of payment.history" :key="index">
          [{{ item.datetime.toDate() | moment('DD/MM/YYYY HH:mm') }}] User: <span class="text-primary">{{item.user.displayName}} ({{ item.user.uid }})</span> Action:<span class="text-primary">{{ item.type }}</span>
        </div>
      </div>
  
  
      <div class="d-flex mt-2 mb-2">
        <router-link v-if="payment.payment_id" :to="`/print/receipt/${payment.payment_id}`" target="_blank" class="btn btn-light"
          style="margin-left: 10px; width: 150px;">
          <i class="fas fa-print"></i> Print Receipt
        </router-link>
        <router-link v-if="payment.payment_id" :to="`/print/receipt/${payment.payment_id}?mode=short`" target="_blank" class="btn btn-light"
          style="margin-left: 10px; width: 150px;">
          <i class="fas fa-print"></i> Print Short Receipt
        </router-link>
        <button v-if="total_outstanding!==0" class="btn btn-primary ml-auto" @click="savePayment"><i class="fas fa-save"></i> Pay</button>
      </div>
    </b-modal>

    <b-modal id="package-modal" hide-footer size="xl" header-bg-variant="gray" :title="`Add package`">
      <div class="container-fluid">
        <MazSelect
          class="ml-auto"
          v-model="selectedPackage"
          search
          :options="prepared_packages.map(i => {
            return { value: i, label: i.package_name }
          })"
        />
      </div>

      <div class="mt-2 mb-2" v-if="selectedPackage">
        <div>Billable Items</div>
        <ul>
          <li v-for="(item, index) in selectedPackage.billable_items" :key="`${item.item_code}-${index}`">[{{ item.item_code }}] {{ _.find(billable_items, { 'item_code': item.item_code }).item_name }}</li>
        </ul>
      </div>
      <div class="mt-2 mb-2" v-if="selectedPackage">
        <div>Products Items</div>
        <ul>
          <li v-for="(item, index) in selectedPackage.product_items" :key="`${item.item_code}-${index}`">[{{ item.item_code }}] {{ _.find(products, { 'item_code': item.item_code }).item_name }}</li>
        </ul>
      </div>
  
      <div class="d-flex mt-2 mb-2">
        <button class="btn btn-primary btn-block" @click="addPackage(selectedPackage)"><i class="fas fa-plus"></i> Add</button>
      </div>
    </b-modal>

    <b-modal id="print-finance-modal" hide-footer size="xl" header-bg-variant="gray" :title="`Print ${currentMenu} Summary`">
      <div class="row">
        <div class="col-md-2 col-sm-12 text-center">
          <MazPicker
            v-model="selectedStartDate"
            no-time
            format="DD/MM/YYYY"
            formatted="DD/MM/YYYY"
            placeholder="Start Date"
          />
        </div>
        <div class="col-md-2 col-sm-12 text-center">
          <MazPicker
            v-model="selectedEndDate"
            no-time
            format="DD/MM/YYYY"
            formatted="DD/MM/YYYY"
            placeholder="End Date"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <a target="_blank" :href="currentMenu === 'Invoice' ? `/print/invoices?start=${selectedStartDate}&end=${selectedEndDate}` : `/print/payments?start=${selectedStartDate}&end=${selectedEndDate}`" class="btn btn-primary"><i class="fas fa-print"></i> Print</a>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import { db,Timestamp } from '../../db'
import draggable from 'vuedraggable'
export default {
  props: ["patient", "visitId", "start", "end"],
  components: {
    draggable
  },
  data() {
    return {
      selectedStartDate: this.$moment().add(-1, 'month').format('DD/MM/YYYY'),
      selectedEndDate: this.$moment().format('DD/MM/YYYY'),
      currentMenu: 'Invoice',
      doctors: [],
      menus: [
        { name: 'Invoice' },
        { name: 'Payment' },
      ],
      timer: new Date(),
      invoiceColumns: [
        {
          label: 'Date Time',
          field: 'issue_date',
        },
        {
          label: 'Visit Date',
          field: 'visit_date',
        },
        {
          label: 'Invoice#',
          field: 'invoice_id',
          width: '250px'
        },
        {
          label: 'Patient Name',
          field: 'patient_name',
          width: '200px'
        },
        {
          label: 'Payment Type',
          field: 'payment_type',
        },
        {
          label: 'Payment Amount',
          field: 'payment_amount',
        },
        {
          label: 'Discount',
          field: this.getTotalDiscount,
        },
        {
          label: '% Extra Charge',
          field: 'extra_charge',
          hidden: true
        },
        {
          label: 'Total Amount',
          field: 'total_invoiced',
        },
        {
          label: 'Outstanding',
          field: this.getOutstanding,
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Print',
          field: 'print',
          hidden: !!this.$route.meta.print
        }
      ],
      paymentColumns: [
        {
          label: 'Datetime',
          field: 'issue_date',
        },
        {
          label: 'Payment#',
          field: 'payment_id',
        },
        {
          label: 'Patient Name',
          field: 'patient_name',
        },
        {
          label: 'Payment Type',
          field: 'payment_type',
        },
        {
          label: 'Payment Amount',
          field: 'payment_type',
        },
        {
          label: 'Total Amount',
          field: this.paymentAmount
        },
        {
          label: 'Print',
          field: 'print',
          hidden: !!this.$route.meta.print
        }
      ],
      invoice: {
        invoice_id: null,
        issue_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        visit_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        concession_type: 'Foreigner',
        insurance_name: null,
        insurance_name_other: null,
        HN: null,
        VN: null,
        patient_name: null,
        status: 'unpaid',
        product_items: [],
        billable_items: [],
        note: null,
        payment_applied: 0,
        outstanding: 0,
        total_invoiced: 0,
        discount: 0
      },
      payment: {
        payment_id: null,
        issue_date: new Date(),
        HN: null,
        VN: null,
        insurance_name: null,
        insurance_name_other: null,
        patient_name: null,
        cash: 0,
        credit: 0,
        credit_bank_name: null,
        insurance: 0,
        other: 0,
        internet_banking: 0
      },
      selectedPackage: null,
      keyword: null,
      showmenu: true,
      invoices: [],
      payments: [],
      patients: [],
      packages: [],
      products: [],
      billable_items: [],
      total_invoiced: 0,
      payment_applied: 0,
      total_outstanding: 0,
      creditcards: [],
      new_credit_bank_name: null,
      visits: []
    }
  },
  firestore() {
    return {
      invoices: db.collection('Invoice').orderBy("issue_date", "desc"),
      payments: db.collection('Payment').orderBy("issue_date", "desc"),
      patients: db.collection('Patient').orderBy("created_at", "desc"),
      products: db.collection('Product'),
      billable_items: db.collection('BillableItem'),
      packages: db.collection('Package'),
      doctors: db.collection('User').where('roles', 'array-contains', 'doctor').where('isApproved', '==', true),
      creditcards: db.collection('CreditCard')
    }
  },
  mounted() {
    if (this.$route.name === "Print Payment Summary") {
      this.currentMenu = 'Payment'
    }
    
    if (this.$route.name === "Print Invoice Summary") {
      this.currentMenu = 'Invoice'
    }
  },
  methods: {
    capitalizeFirstChar(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    async saveInvoice() {
      let loader = this.$loading.show({});
      let isNewInvoice = !this.invoice.invoice_id

      if(!this.invoice.patient_name) {
        loader.hide()
        this.$alert('Please select patient')
        return
      }
      if(!this.invoice.type) {
        loader.hide()
        this.$alert('Please select type OPD/IPD')
        return
      }
      if(!this.invoice.VN || !this.invoice.visit_date){
        loader.hide()
        this.$alert('No visit information, please select visit(VN)', null, 'error')
        return
      }

      if(isNewInvoice) {
        this.invoice.history = [{
          type: 'create',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        }]
      }else{
        this.invoice.history.push({
          type: 'edit',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        })
      }

      try {
        let sod = this.$moment().startOf('day').toDate()
        let eod = this.$moment().endOf('day').toDate()
        let latestInvoice = await db.collection('Invoice')
          .where('issue_date', '<', eod)
          .where('issue_date', '>=', sod)
          .get()
        let invoicesToday = latestInvoice.docs.length
        console.log('current invoice today', invoicesToday)
        console.log(this.invoice)

        const randomset = this.generateRandomString()
        let invoice_id
        if (this.invoice.invoice_id) {
          invoice_id = this.invoice.invoice_id
        } else {
          invoice_id = `${String(this.$store.state.branch).padStart(2, '0')}-${this.$moment().format('YYYY')}-${String(invoicesToday + 1).padStart(4, '0')}-${this.$moment().format('MM-DD')}${randomset}`
        }
        console.log({ invoice_id })
        this.invoice.invoice_id = invoice_id
        let total_invoiced = 0
        for (let item of this.invoice.billable_items) {
          item.quantity = Number(item.quantity)
          item.price = Number(item.price)
          item.discount = Number(item.discount)
          if(Number(item.discount)<0 || Number(item.discount)>100){
            this.$alert('discount should be 0-100%')
            loader.hide()
            return
          }
          if(Number(item.price)<0){
            this.$alert('price can not below 0')
            loader.hide()
            return
          }
          if (Number(item.quantity) < 0) {
            this.$alert('quantity can not below 0')
            loader.hide()
            return
          }
          total_invoiced += (Number(item.price) - Number(Number(item.price) * item.discount / 100)) * Number(item.quantity)
        }
        for (let item of this.invoice.product_items) {
          item.quantity = Number(item.quantity)
          item.price = Number(item.price)
          item.discount = Number(item.discount)
          if (Number(item.discount) < 0 || Number(item.discount) > 100) {
            this.$alert('discount should be 0-100%')
            loader.hide()
            return
          }
          if (Number(item.price) < 0) {
            this.$alert('price can not below 0')
            loader.hide()
            return
          }
          if (Number(item.quantity) < 0) {
            this.$alert('quantity can not below 0')
            loader.hide()
            return
          }
          total_invoiced += (Number(item.price) - Number(Number(item.price) * item.discount / 100)) * Number(item.quantity)
        }
        this.invoice.total_invoiced = total_invoiced
        if (!this.invoice.payment_applied) this.invoice.payment_applied = 0

        let invoice = {
          ...this.invoice
        }

        invoice.issue_date = this.$moment(invoice.issue_date, 'DD/MM/YYYY HH:mm').toDate()
        invoice.visit_date = this.$moment(invoice.visit_date, 'DD/MM/YYYY HH:mm').toDate()

        await db.collection('Invoice').doc(invoice_id).set(invoice)
        loader.hide()
        this.$alert("Save invoice success.", null, "success");
        // this.$bvModal.hide('new-invoice-modal')
      } catch (error) {
        console.error(error)
        this.$alert(`error ${error}`, null, "error");
        loader.hide()
      }


    },
    deleteInvoice(invoiceId) {
      this.$confirm('Do you want to delete this invoice?').then(async () => {
        try {
          
          await db.collection('Invoice').doc(invoiceId).delete()
  
          let paymentRefs = await db.collection('Payment').where('invoice_id', '==', invoiceId).get()
          let deletedPaymentsText = ``
          for(let payment of paymentRefs.docs) {
            await payment.ref.delete()
            deletedPaymentsText += `payment: ${payment.id} was deleted\n`
          }
  
          this.$alert(`invoice: ${invoiceId} was deleted\n`+deletedPaymentsText, null, 'success')
        } catch (error) {
          this.$alert(error, null , 'error')
        }

        this.$bvModal.hide('new-invoice-modal')
      })
    },
    async savePayment() {
      let loader = this.$loading.show({});
      let totalPayment = Number(this.payment.cash) + Number(this.payment.credit) + Number(this.payment.insurance) + Number(this.payment.internet_banking) + Number(this.payment.other)

      //payment can not below 0
      if(Number(this.payment.cash)<0 || Number(this.payment.credit)<0 || Number(this.payment.insurance)<0 || Number(this.payment.internet_banking)<0 || Number(this.payment.other)<0 ) {
        this.$alert(`payment can not below 0`)
        loader.hide()
        return
      }
      //payment can not be 0
      if(Number(totalPayment) === 0) {
        this.$alert(`payment can not be 0`)
        loader.hide()
        return
      }

      // if (Number(totalPayment) > Number(this.total_outstanding)) {
      //   this.$alert(`Payment higher than outstanding`)
      //   loader.hide()
      //   return
      // }

      let isNewPayment = !this.payment.payment_id

      if(isNewPayment) {
        this.payment.history = [{
          type: 'create',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        }]
      }else{
        this.payment.history.push({
          type: 'edit',
          datetime: Timestamp.fromDate(new Date()),
          user: {
            uid: this.$store.state.user.uid,
            displayName: this.$store.state.user.displayName,
            email: this.$store.state.user.email
          }
        })
      }
      try {
        let sod = this.$moment().startOf('day').toDate()
        let eod = this.$moment().endOf('day').toDate()
        let latestPayment = await db.collection('Payment')
          .where('issue_date', '<', eod)
          .where('issue_date', '>=', sod)
          .get()
        let paymentsToday = latestPayment.docs.length
        let payment_id

        const randomset = this.generateRandomString()
        if (this.payment.payment_id) {
          payment_id = this.payment.payment_id
        } else {
          payment_id = `${String(this.$store.state.branch).padStart(2, '0')}-${this.$moment().format('YYYY')}-${String(paymentsToday + 1).padStart(4, '0')}-${this.$moment().format('MM-DD')}${randomset}`
        }
        if(!this.payment.insurance_name) this.payment.insurance_name=null
        if(!this.payment.insurance_name_other) this.payment.insurance_name_other=null
        this.payment.payment_id = payment_id
        this.payment.cash = Number(this.payment.cash) || 0
        this.payment.credit = Number(this.payment.credit) || 0
        this.payment.insurance = Number(this.payment.insurance) || 0
        this.payment.internet_banking = Number(this.payment.internet_banking) || 0
        this.payment.other = Number(this.payment.other) || 0
        this.payment.total_payment = this.payment.cash + this.payment.credit + this.payment.insurance + this.payment.internet_banking + this.payment.other
        
        await db.collection('Payment').doc(payment_id).set(this.payment)

        //update status
        let invoiceRef = await db.collection('Invoice').doc(this.payment.invoice_id).get()
        let invoice = {
          ...invoiceRef.data()
        }
        let payment_applied = await this.totalPaymentApplied(invoice)
        if(Number(invoice.total_invoiced) === Number(payment_applied)) {
          await db.collection('Invoice').doc(this.payment.invoice_id).update({
            status: 'paid',
            payment_applied
          })
        }else{
          await db.collection('Invoice').doc(this.payment.invoice_id).update({
            status: 'outstanding',
            payment_applied
          })
        }
        loader.hide()
        this.$alert("Payment completed.", null, "success");
        this.showPayment(payment_id)
      } catch (error) {
        console.error(error)
        this.$alert(`error`, error)
        loader.hide()
      }
    },
    async showNewInvoice() {
      this.invoice = {
        invoice_id: null,
        issue_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        visit_date: this.$moment().format('DD/MM/YYYY HH:mm'),
        concession_type: 'Foreigner',
        insurance_name: null,
        insurance_type: null,
        insurance_name_other: null,
        HN: null,
        VN: null,
        patient_name: null,
        status: 'unpaid',
        product_items: [],
        billable_items: [],
        note: null,
        payment_applied: 0,
        outstanding: 0,
        total_invoiced: 0,
        practitioner: null
      }

      if (this.patient) {
        this.invoice.HN = this.patient.HN
        this.invoice.patient_name = `${this.patient.first_name} ${this.patient.last_name}`

        if (this.patient.travel_insurance) {
          this.invoice.concession_type = 'Insurance'
          this.invoice.insurance_name = this.patient.travel_insurance?.name_en || null
          this.invoice.insurance_type = this.patient.travel_insurance?.type || null
          this.invoice.insurance_name_other = this.patient.travel_insurance_other || null

          this.invoice.billed_to = this.patient.travel_insurance?.name_en || null
        }else if (this.patient.is_resident || this.patient.is_resident) {
          this.invoice.concession_type = 'Resident'
          this.invoice.billed_to = `${this.patient.first_name} ${this.patient.last_name}`
        }else{
          this.invoice.concession_type = 'Foreigner'
          this.invoice.billed_to = `${this.patient.first_name} ${this.patient.last_name}`
        }

        this.visits = []
        let visitsRef = await db
          .collection('Visit')
          .where('patient','==', db.collection('Patient').doc(this.patient.HN))
          .orderBy('visit_date', 'desc')
          .get()
        if(visitsRef.docs.length>0){
          this.visits = visitsRef.docs.map(item => {
            return {
              VN: item.data().VN,
              visit_date: item.data().visit_date?.toDate()
            }
          })
          // this.invoice.VN = this.visits[0].VN
          this.invoice.visit_date = this.$moment(this.visits[0].visit_date).format('DD/MM/YYYY HH:mm')
        }else{
          // this.invoice.VN = null
          this.invoice.visit_date = this.$moment().format('DD/MM/YYYY HH:mm')
          this.$alert('No visit information, patient is required to  register new visit first before proceed.', null, 'error')
        }
        
      }

      if(this.visitId) {
        this.invoice.VN = this.visitId
        if (this.invoice.VN) {
          let visitRef = await db.collection('Visit').doc(this.invoice.VN).get()
          if (visitRef.exists) {
            this.invoice.visit_date = this.$moment(visitRef.data().visit_date?.toDate()).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.$bvModal.show('new-invoice-modal')
    },
    async showInvoice(invoiceId) {
      let loader = this.$loading.show({});
      try {
        let invoiceRef = await db.collection('Invoice').doc(invoiceId).get() 
        this.invoice = {
          ...invoiceRef.data(),
          issue_date: this.$moment(invoiceRef.data().issue_date?.toDate()).format('DD/MM/YYYY HH:mm'),
          visit_date: this.$moment(invoiceRef.data().visit_date?.toDate()).format('DD/MM/YYYY HH:mm'),
        }
      } catch (error) {
        loader.hide()
        this.$alert('error: ', error)
        return
      }
      loader.hide()
      this.$bvModal.show('new-invoice-modal')
    },
    async showNewPayment(invoice) {
      this.payment = {
        payment_id: null,
        invoice_id: invoice.invoice_id,
        issue_date: new Date(),
        HN: invoice.HN ? invoice.HN : null,
        VN: invoice.VN ? invoice.VN : null,
        patient_name: invoice.patient_name ? invoice.patient_name : null,
        billed_to: invoice.billed_to ? invoice.billed_to : null,
        cash: 0,
        credit: 0,
        insurance: 0,
        other: 0,
        internet_banking: 0,
        concession_type: invoice.concession_type,
        insurance_name: invoice.insurance_name || null,
        insurance_type: invoice.insurance_type || null,
        insurance_name_other: invoice.insurance_name_other || null,
        practitioner: invoice.practitioner,
        type: invoice.type,
        note: invoice.note || null,
        extra_charge: 0.03
      }
      this.total_invoiced = invoice.total_invoiced
      this.payment_applied = await this.totalPaymentApplied(invoice)
      this.total_outstanding = await this.totalOutstanding(invoice)
      this.$bvModal.show('new-payment-modal')
    },
    async showPayment(paymentId) {
      console.log(paymentId)
      let loader = this.$loading.show({})
      // let paymentId = payment.row.payment_id

      try {
        let paymentRef = await db.collection('Payment').doc(paymentId).get()
        this.payment = {
          ...paymentRef.data()
        }
        let invoiceRef = await db.collection('Invoice').doc(this.payment.invoice_id).get()
        let invoice = {
          ...invoiceRef.data()
        }
        this.total_invoiced = invoice.total_invoiced
        this.payment_applied = await this.totalPaymentApplied(invoice)
        this.total_outstanding = await this.totalOutstanding(invoice)
      } catch (error) {
        loader.hide()
        this.$alert('error: ', error)
        return
      }
      loader.hide()
      this.$bvModal.show('new-payment-modal')
    },
    addProduct() {
      this.invoice.product_items.push({ price: 0, quantity: 1, discount: 0 })
    },
    addBillableItem() {
      this.invoice.billable_items.push({ price: 0, quantity: 1, discount: 0 })
    },
    addPackage(med_package) {
      for (let item of med_package.billable_items) {
        let billable_item = _.find(this.prepared_billable_items, { 'item_code': item.item_code })
        let data = {
          item_code: item.item_code,
          item: billable_item,
          discount: billable_item.discount ? billable_item.discount : 0,
          quantity: billable_item.quantity ? billable_item.quantity : 1
        }

        switch (this.invoice.concession_type) {
          case 'Insurance':
            data.price = billable_item.price_insurance
            break;
          case 'Resident':
            data.price = billable_item.price_th
            break;
          default:
            data.price = billable_item.price_inter
            break;
        }

        this.invoice.billable_items.push(data)
      }
      for (let item of med_package.product_items) {
        let product_item = _.find(this.prepared_products, { 'item_code': item.item_code })
        let data = {
          item_code: item.item_code,
          item: product_item,
          discount: product_item.discount ? product_item.discount : 0,
          quantity: product_item.quantity ? product_item.quantity : 1
        }

        switch (this.invoice.concession_type) {
          case 'Insurance':
            data.price = product_item.price_insurance
            break;
          case 'Resident':
            data.price = product_item.price_th
            break;
          default:
            data.price = product_item.price_inter
            break;
        }

        this.invoice.product_items.push(data)
      }

      this.selectedPackage = null
      this.$bvModal.hide('package-modal')
    },
    showPackage() {
      this.selectedPackage = null
      this.$bvModal.show('package-modal')
    },
    handleProductChanged(index) {
      console.log(this.invoice.product_items[index])
      switch (this.invoice.concession_type) {
        case 'Insurance':
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_insurance
          break;
        case 'Resident':
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_th
          break;
        default:
          this.invoice.product_items[index].price = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code }).price_inter
          break;
      }
      this.invoice.product_items[index].item = _.find(this.prepared_products, { 'item_code': this.invoice.product_items[index].item_code })
    },
    handleBillableItemChanged(index) {
      console.log(this.invoice.billable_items[index])
      switch (this.invoice.concession_type) {
        case 'Insurance':
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_insurance
          break;
        case 'Resident':
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_th
          break;
        default:
          this.invoice.billable_items[index].price = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code }).price_inter
          break;
      }

      this.invoice.billable_items[index].item = _.find(this.prepared_billable_items, { 'item_code': this.invoice.billable_items[index].item_code })
    },
    handleConcessionChanged() {
      for (let item of this.invoice.product_items) {
        switch (this.invoice.concession_type) {
          case 'Insurance':
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_insurance
            break;
          case 'Resident':
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_th
            break;
          default:
            item.price = _.find(this.prepared_products, { 'item_code': item.item_code })?.price_inter
            break;
        }
      }
      for (let item of this.invoice.billable_items) {
        switch (this.invoice.concession_type) {
          case 'Insurance':
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_insurance
            break;
          case 'Resident':
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_th
            break;
          default:
            item.price = _.find(this.prepared_billable_items, { 'item_code': item.item_code })?.price_inter
            break;
        }
      }
    },
    calculateTotalAmount(rowObj) {
      let invoice = rowObj
      return invoice.total_invoiced
    },
    getTotalDiscount(rowObj){
      let invoice = rowObj
      let sum_product = _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      let sum_billable_items = _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))

      return sum_billable_items + sum_product
    },
    getOutstanding(rowObj) {
      let invoice = rowObj
      let total_payment_applied=0
      for (let payment of invoice.payments) {
        total_payment_applied += payment.total_payment
      }
      return (Number(invoice.total_invoiced) - Number(total_payment_applied)).toLocaleString()
    },
    paymentAmount(rowObj) {
      let payment = rowObj
      return Number(payment.cash) + Number(payment.credit) + Number(payment.other) + Number(payment.insurance) + Number(payment.internet_banking)
    },
    async totalPaymentApplied(invoice) {
      if (invoice.invoice_id) {
        try {
          let payments = await db.collection('Payment').where('invoice_id', '==', invoice.invoice_id).get()
          let total_payment_applied = 0
          for (let payment of payments.docs) {
            total_payment_applied += payment.data().total_payment
          }
          return total_payment_applied
        } catch (error) {
          return 0
        }
      } else {
        return 0
      }

    },
    async totalOutstanding(invoice) {
      return invoice.total_invoiced - (await this.totalPaymentApplied(invoice))
    },
    async handlePatientNameSelect(data) {
      let loader = this.$loading.show({})
      this.visits = []
      try {
        let patientRef = await db.collection('Patient').doc(data).get()
        let patient = {
          ...patientRef.data()
        }
        if (patient.travel_insurance) {
          let insuranceRef = await db.collection('Insurance').doc(patient.travel_insurance.id).get()
          patient.travel_insurance = {
            id: patient.travel_insurance.id,
            ...insuranceRef.data()
          }
        }

        if (patient) {
          this.invoice.HN = patient.HN
          this.invoice.patient_name = `${patient.first_name || ''} ${patient.last_name || ''}`
          if (patient.travel_insurance) {
            this.invoice.concession_type = 'Insurance'
            this.invoice.insurance_name = patient.travel_insurance?.name_en || null
            this.invoice.insurance_type = patient.travel_insurance?.type || null

            //init billed to
            this.invoice.billed_to = patient.travel_insurance?.name_en || null
            if (patient.travel_insurance.id === 'Other') {
              this.invoice.insurance_name_other = patient.travel_insurance_other
            }
            
          } else if (patient.is_resident || patient.is_resident) {
            this.invoice.concession_type = 'Resident'
            this.invoice.billed_to = `${patient.first_name || ''} ${patient.last_name || ''}`
          } else {
            this.invoice.concession_type = 'Foreigner'
            this.invoice.billed_to = `${patient.first_name || ''} ${patient.last_name || ''}`
          }

          let visitsRef = await db
            .collection('Visit')
            .where('patient','==', db.collection('Patient').doc(patient.HN))
            .orderBy('visit_date', 'desc')
            .get()
          if(visitsRef.docs.length>0){
            this.visits = visitsRef.docs.map(item => {
              return {
                VN: item.data().VN,
                visit_date: item.data().visit_date?.toDate()
              }
            })
            // this.invoice.VN = this.visits[0].VN
            this.invoice.visit_date = this.$moment(this.visits[0].visit_date).format('DD/MM/YYYY HH:mm')
          }else{
            // this.invoice.VN = null
            this.invoice.visit_date = this.$moment().format('DD/MM/YYYY HH:mm')
            this.$alert('No visit information, patient is required to  register new visit first before proceed.', null, 'error')
          }
        }
        loader.hide()
        this.handleConcessionChanged()
      } catch (error) {
        loader.hide()
        this.$alert(`error:${error}`, null, 'error')
      }
    },
    calculateWidthByContent(column, index, allColumns) {
      const rows = this.rows;
      const widths = [column.label.length * 10]; // Initial width based on header label length

      rows.forEach(row => {
        const cellContent = row[column.field];
        const cellWidth = cellContent ? cellContent.toString().length * 10 : 0;
        if (cellWidth > widths[index]) {
          widths[index] = cellWidth;
        }
      });

      return widths[index] + "px";
    },
    generateRandomString() {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const first_digit = alphabet[Math.floor(Math.random() * alphabet.length)];
      const second_digit = alphabet[Math.floor(Math.random() * alphabet.length)];
      const third_digit = Math.floor(Math.random() * 10);

      return `${first_digit}${second_digit}${third_digit}`
    },
    applyDiscount(discount) {
      console.log({discount})
      this.invoice.product_items.map( item => {
        item.discount = Number(discount)
      })
      this.invoice.billable_items.map( item => {
        item.discount = Number(discount)
      })
    }
  },
  computed: {
    preparedDiscount() {
      let data = []
      for(let i = 0; i<= 30; i+= 0.5) {
        data.push({
          name: `${i} %`, id: i
        })
      }
      return data
    },
    preparedPatients() {
      return this.patients.map(p => {
        return {
          name: `${p.first_name || ''} ${p.last_name || ''}`,
          HN:p.HN
        }
      })
    },
    filteredPayments() {
      let items = []
      if (this.keyword) {

        for (let item of this.payments) {
          if (
            item.id.indexOf(this.keyword.toUpperCase()) !== -1 ||
            (item.patient_name && item.patient_name.toUpperCase().indexOf(this.keyword.toUpperCase()) !== -1)
          ) items.push(item)
        }
        return items
      } else {
        return this.payments
      }
    },
    totalDiscount() {
      let sum_product = _.sum(this.invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      let sum_billable_items = _.sum(this.invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * Number(item.price) * Number(item.discount) / 100
        } else {
          return 0
        }
      }))
      const res = Math.ceil(sum_product + sum_billable_items)
      return res
    },
    totalAmount() {
      let invoice = this.invoice
      let sum = _.sum(invoice.product_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) - (Number(item.price) * Number(item.discount) / 100))
        } else {
          return 0
        }
      })) + _.sum(invoice.billable_items.map(item => {
        if (item.quantity && item.price) {
          return Number(item.quantity) * (Number(item.price) - (Number(item.price) * Number(item.discount) / 100))
        } else {
          return 0
        }
      }))
      const res = Math.ceil(sum)
      return res
    },
    prepared_products() {
      return this.products.map(i => {
        return {
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null
        }
      })
    },
    prepared_billable_items() {
      return this.billable_items.map(i => {
        return {
          item_name: i.item_name || null,
          item_type: i.item_type || null,
          item_code: i.item_code || null,
          price_th: Number(i.price_th) || null,
          price_inter: Number(i.price_inter) || null,
          price_insurance: Number(i.price_insurance) || null
        }
      })
    },
    prepared_packages() {
      return this.packages.map(i => {
        return {
          package_name: i.package_name,
          package_code: i.package_code,
          billable_items: i.billable_items,
          product_items: i.product_items
        }
      })
    },
    preparedInvoices() {
      let filteredInvoices = []

      if (this.keyword) {
        filteredInvoices = _.filter(_.clone(this.invoices), item => {

          if(this.patient?.HN) {
            return (
              item.invoice_id.includes(this.keyword) ||
              `${item.patient_name?.toLowerCase()}`.includes(
                this.keyword.toLowerCase()
              ) && (item.HN === this.patient.HN)
            );
          }else {
            return (
              item.invoice_id.includes(this.keyword) ||
              `${item.patient_name?.toLowerCase()}`.includes(
                this.keyword.toLowerCase()
              )
            );
          }
          
        });
      }else{
        if(this.patient?.HN && this.visitId){
          filteredInvoices = this._.filter(this.invoices, {'HN': this.patient.HN, 'VN': this.visitId})
        }else if(this.patient?.HN){
          filteredInvoices = this._.filter(this.invoices, {'HN': this.patient.HN})
        }else{
          filteredInvoices = _.clone(this.invoices)
        }
      }


      let preparedInvoices = []
      //add payment data to invoices
      for(let item of filteredInvoices) {
        let payments = this._.filter(this.payments, { 'invoice_id': item.invoice_id})
        preparedInvoices.push({
          ...item,
          payments: payments
        })
      }

      return preparedInvoices

    },
    preparedPayments() {
      let filteredItems = []

      if (this.keyword) {
        filteredItems = _.filter(_.clone(this.payments), item => {

          if(this.patient?.HN) {
            return (
              item.payment_id.includes(this.keyword) ||
              `${item.patient_name?.toLowerCase()}`.includes(
                this.keyword.toLowerCase()
              ) && (item.HN === this.patient.HN)
            );
          }else {
            return (
              item.payment_id.includes(this.keyword) ||
              `${item.patient_name?.toLowerCase()}`.includes(
                this.keyword.toLowerCase()
              )
            );
          }
          
        });
      }else{
        if(this.patient?.HN && this.visitId){
          filteredItems = this._.filter(this.payments, {'HN': this.patient.HN, 'VN': this.visitId})
        }else if(this.patient?.HN){
          filteredItems = this._.filter(this.payments, {'HN': this.patient.HN})
        }else{
          filteredItems = _.clone(this.payments)
        }
      }

      return filteredItems

    },
    groupedInvoices() {
      let invoices = []
      if (this.start && this.end) {
        invoices = _.filter(this.preparedInvoices, item => {
          return (
            this.$moment(item.issue_date.toDate()).isBefore(
              this.$moment(this.end, "DD/MM/YYYY").endOf('day')
            ) &&
            this.$moment(item.issue_date.toDate()).isAfter(
              this.$moment(this.start, "DD/MM/YYYY").startOf('day')
            )
          );
        });
      }else{
        invoices = _.clone(this.preparedInvoices)
      }

      let itemGroups = [];
      let groupedItems = _.groupBy(invoices, item => {
        return this.$moment(item.issue_date.toDate()).format("DD MMM YYYY");
      });

      for (let group in groupedItems) {
        let groupLabel = {
          mode: "span",
          label: group,
          html: false,
          children: []
        };

        groupLabel;
        for (let item of groupedItems[group]) {
          groupLabel.children.push(item);
        }

        itemGroups.push(groupLabel);
      }

      return itemGroups;
    },
    groupedPayments() {
      let items = []
      if (this.start && this.end) {
        items = _.filter(this.preparedPayments, item => {
          return (
            this.$moment(item.issue_date.toDate()).isBefore(
              this.$moment(this.end, "DD/MM/YYYY").endOf('day')
            ) &&
            this.$moment(item.issue_date.toDate()).isAfter(
              this.$moment(this.start, "DD/MM/YYYY").startOf('day')
            )
          );
        });
      }else{
        items = _.clone(this.preparedPayments)
      }

      for(let item of items) {
        item.preparedPayments = []
        if(item.cash > 0) {
          item.preparedPayments.push({
            name: `Cash`,
            amount: item.cash
          })
        } 
        if(item.credit > 0) {
          item.preparedPayments.push({
            name: `Credit ${item.credit_bank_name? '('+this.capitalizeFirstChar(item.credit_bank_name)+')' : ''}`,
            amount: item.credit
          })
        } 
        if(item.insurance > 0) {
          item.preparedPayments.push({
            name: 'Insurance',
            amount: item.insurance
          })
        } 
        if(item.internet_banking > 0) {
          item.preparedPayments.push({
            name: 'internet_banking',
            amount: item.internet_banking
          })
        } 
        if(item.other > 0) {
          item.preparedPayments.push({
            name: item.other_name || 'Other',
            amount: item.other
          })
        } 
      }

      let itemGroups = [];
      let groupedItems = _.groupBy(items, item => {
        return this.$moment(item.issue_date.toDate()).format("DD MMM YYYY");
      });

      for (let group in groupedItems) {
        let groupLabel = {
          mode: "span",
          label: group,
          html: false,
          children: []
        };

        groupLabel;
        for (let item of groupedItems[group]) {
          groupLabel.children.push(item);
        }

        itemGroups.push(groupLabel);
      }

      return itemGroups;
    }
  }
}
</script>


<style lang="scss" scoped>
.ongoing {
  border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;
  
	background: rgba(255, 193, 51, 1);
	box-shadow: 0 0 0 0 rgba(255, 193, 51, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

.paid {
  border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;

  background: rgba(0, 215, 42, 1);
  box-shadow: 0 0 0 0 rgba(0, 215, 42, 1);
  transform: scale(1);
	animation: pulsegreen 2s infinite;
}

.waiting {
  border-radius: 50%;
	margin: 10px;
	height: 20px;
	width: 20px;

  box-shadow: 0 0 0 0 rgba(255, 59, 59, 1);
  background: rgba(255, 59, 59, 1);
  transform: scale(1);
	animation: pulsered 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 193, 51, 0.4);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 193, 51, 0.2);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 193, 51, 0);
	}
}
@keyframes pulsegreen {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 215, 42, 0.4);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 215, 42, 0.2);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 215, 42, 0);
	}
}
@keyframes pulsered {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 59, 59, 0.4);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 59, 59, 0.2);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 59, 59, 0);
	}
}
</style>