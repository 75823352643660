<template>
  <div class="card shadow mt-2 mb-2">
    <div class="card-body row mt-2">
      <div class="col-md-6 col-sm-12">
        <table class="table table-bordered table-striped">
          <thead>
            <th colspan="4" class="text-center">Card Payer</th>
          </thead>
          <thead>
            <th>Name</th>
            <th>Bank Name</th>
            <th>Time</th>
            <th class="text-right">Amount</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedPayments" :key="'inter'+index">
              <td><a :href="`/print/receipt/${item.payment_id}`" target="_blank">{{item.patient_name}}</a></td>
              <td>{{ item.credit_bank_name ? item.credit_bank_name:'Other'}}</td>
              <td>{{item.issue_date.toDate() | moment('DD/MM/YYYY HH:mm a')}}</td>
              <td class="text-right" >{{item.credit.toLocaleString()}}</td>
            </tr>
            <tr>
              <td colspan="3">Total</td>
              <td class="text-right">{{ _.sumBy( _.filter(this.payments, p => p.credit>0 ), item => item.credit ).toLocaleString()}}</td>
            </tr>
          </tbody>
        </table>
        <table-pagination :items="filteredPayments" :onChange="(items) => { paginatedPayments = items}" :perPage="10"/>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="d-flex mt-2 mb-2">
          <h5 class="text-primary mr-auto">Banks by Card</h5>
          <button 
            class="btn btn-primary ml-auto" 
            @click="$bvModal.show('card-by-bank-setting')">
              <i class="fas fa-cog"></i>
          </button>
        </div>
        <Bar  :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'credit card by bank'"
          :width="400" :height="400" />

        <modal-graph-color-setting 
          @change="saveColor"
          v-model="colorList" 
          :id="'card-by-bank-setting'" 
          :legends="legendList"/>
      </div>
    </div>
  </div>
</template>

<script>
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Bar 
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  mixins: [GraphColorSettingMixins],
  components: {
    Bar,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'CardByBankGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'end',
            align: 'top',
            formatter: value => {
              return value.toLocaleString()
            }
          },
          legend: {
            display: false
          },
        },
        layout: {
          padding: {
            top: 50, 
          },
        }
      },
      preparedPayments: [],
      paginatedPayments: []
    }
  },
  watch:{
    payments: {
      handler() {
        this.setLegendList(this.data.map( item => item.credit_bank_name))
        this.setColorList(this.generateBackgroundColor(this.data, 'credit_bank_name'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.data.map( item => item.credit_bank_name))
        this.setColorList(this.generateBackgroundColor(this.data, 'credit_bank_name'))
      },
      immediate: true
    },
  },
  computed: {
    filteredPayments() {
      return this._.filter(this.payments, p => p.credit>0 )
    },
    data() {
      let data = [{
        credit_bank_name: 'Other',
        amount: 0,
        index: 0
      }]

      for (let item of this.payments) {

        if(item.credit > 0) {
          let findIndex = this._.findIndex(data, {credit_bank_name: item.credit_bank_name})
          if(!item.credit_bank_name) {
            data[0].amount += item.credit
          }else if(findIndex>-1) {
            data[findIndex].amount += item.credit
          }else{
            data.push({
              credit_bank_name: item.credit_bank_name,
              amount: 0+item.credit,
              index: data.length
            })
          }
        }
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: this.data.map(d => d.credit_bank_name),
        datasets: [{
          label: 'Other',
          data: this.data.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>