var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{staticClass:"mt-3",attrs:{"pagination-options":{
      enabled: true,
      perPageDropdown: [30, 40, 50, 100],
      dropdownAllowAll: false,
    },"columns":_vm.columns,"rows":_vm.filteredItems,"fixed-header":true,"line-numbers":true,"sort-options":{
      enabled: false
    },"group-options":{
      enabled: true
    }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'details')?_c('div',[_c('div',{staticClass:"text-dark"},[_c('b',[_vm._v(_vm._s(props.row.patient.first_name)+" "+_vm._s(props.row.patient.last_name))])]),_c('div',{staticClass:"text-muted"},[_c('i',[_vm._v("HN: "+_vm._s(props.row.patient.HN))])])]):(props.column.label == 'Visit Type')?_c('div',[(props.row.visit_type === 'New Patient')?_c('span',{staticClass:"new-patient"},[_vm._v(" NEW PATIENT ")]):_vm._e(),(props.row.visit_type === 'Follow up')?_c('span',{staticClass:"follow-up"},[_vm._v(" FOLLOW UP ")]):_vm._e(),(!props.row.visit_type)?_c('span',{staticClass:"old-data"},[_vm._v(" OLD DATA ")]):_vm._e()]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }