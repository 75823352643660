<template>
  <div>
    <h5 class="text-primary">Latest Cash Payment Update</h5>
    <ul>
      <li :key="'livepayment'+index" v-for="(item, index) in cashPayments.slice((perPage*(currentPage-1)), (perPage*(currentPage-1))+(perPage*(currentPage-1)+10))">
        <a :href="`/print/receipt/${item.payment_id}`" target="_blank">{{ item.patient_name }}</a> paid by {{ paidBy(item)}} 
        <div><i>{{ item.issue_date.toDate() | moment('DD MMM YYYY hh:mma') }}</i></div>
      </li>
      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
      ></b-pagination>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['payments'],
  data() {
    return {
      currentPage: 1,
      perPage: 10
    };
  },
  methods: {
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    paidBy(item) {

      if(item.cash){
        return `Cash ${item.cash.toLocaleString()}`
      }
      if(item.credit){
        return `Credit Card ${item.credit.toLocaleString()}`
      }
      if(item.insurance){
        return `Insurance ${item.insurance.toLocaleString()}`
      }
      if(item.internet_banking){
        return `Internet Banking ${item.internet_banking.toLocaleString()}`
      }
      if(item.cash){
        return `Other (${item.other_name}) ${item.other.toLocaleString()}`
      }

    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    totalItems() {
      return this.cashPayments?.length || 0
    },
    pageNumbers() {
      const numbers = [];
      for (let i = 1; i <= this.totalPages; i++) {
        numbers.push(i);
      }
      return numbers;
    },
    cashPayments() {
      return this.payments.filter(p => p.cash>0)
    }
  },
}
</script>