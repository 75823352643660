<template>
  <div class="mt-4 mb-4">
    <h5 class="text-primary">Total Income</h5>
    <hr>
    <div class="card shadow">
      <div class="card-body row">
        <div class="col-md-8 col-sm-12">
          <table class="table table-bordered table-striped">
            <thead>
              <th colspan="2" class="text-center">Income</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in this.paymentTable" :key="'income'+index">
                <td>{{item.name}}</td>
                <td class="text-right">{{item.amount.toLocaleString()}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="d-flex mb-2 mt-2">
            <h5 class="text-primary">Income</h5>
            <button 
              class="btn btn-primary ml-auto" 
              @click="$bvModal.show('total-income-setting')">
                <i class="fas fa-cog"></i>
            </button>
          </div>
          <Doughnut :chart-options="chartOptions" :chart-data="chartData" :dataset-id-key="'label'" :chart-id="'income-chart'"
            :width="800" :height="400" />

          <modal-graph-color-setting 
            @change="saveColor"
            v-model="colorList" 
            :id="'total-income-setting'" 
            :legends="legendList"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Doughnut
} from 'vue-chartjs/legacy'

export default {
  props: ['payments'],
  mixins: [GraphColorSettingMixins],
  components: {
    Doughnut,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'TotalIncomeGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              if(value/this.total > 0.05) {
                const dataset = context.dataset;
                const total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                const percentage = ((value / total) * 100).toFixed(2);
                return `${value.toLocaleString()} \n(${Math.round(percentage)}%)`;

              }else{
                return ''
              }
            }
          },
        }
      }
    }
  },
  watch:{
    payments: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.label))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'label'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.label))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'label'))
      },
      immediate: true
    },
  },
  computed: {
    preparedData() {
      let data = [
        {
          label: 'Cash',
          amount: 0,
          index: 0
        },
        {
          label: 'Credit Card',
          amount: 0,
          index: 1
        },
        {
          label: 'Insurance',
          amount: 0,
          index: 2
        },
        {
          label: 'Internet Banking',
          amount: 0,
          index: 3
        },
        {
          label: 'Other',
          amount: 0,
          index: 4
        }
      ]

      for (let item of this.payments) {
        data[0].amount += item.cash
        data[1].amount += item.credit
        data[2].amount += item.insurance
        data[3].amount += item.internet_banking
        data[4].amount += item.other
      }

      return data
    },
    chartData() {

      let chartData = {
        labels: this.preparedData.map(item => item.label),
        datasets: [
          {
            label: 'income&expense',
            data: this.preparedData.map(d => d.amount),
            backgroundColor: this.colorList
          },
        ]
      }

      return chartData
    },
    paymentTable() {
      let data = [
        { name: 'Cash', amount: 0 },
        { name: 'Credit Card', amount: 0 },
        { name: 'Insurance', amount: 0 },
        { name: 'Internet Banking', amount: 0 },
        { name: 'Other', amount: 0 },
        { name: 'Total', amount: 0 },
      ]

      for (let item of this.payments) {
        data[0].amount += item.cash
        data[1].amount += item.credit
        data[2].amount += item.insurance
        data[3].amount += item.internet_banking
        data[4].amount += item.other
        data[5].amount += (item.cash + item.credit + item.insurance + item.other + item.internet_banking)
      }

      return data
    },
    total() {
      let total = 0

      for (let item of this.payments) {
        total += (item.cash + item.credit + item.insurance + item.other + item.internet_banking)
      }

      return total
    }
  }
}
</script>