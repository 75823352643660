<template>
  <div class="container-fluid">
    <div class="card shadow">
      <div class="card-body" style="min-height: 600px;">
        <div class="d-flex">
          <h3 class="text-primary mr-auto">Statistic</h3>
          <button class="btn btn-primary" @click="loadData"><i class="fas fa-sync"></i> Reload Data</button>
        </div>
        <hr />
        <b-nav tabs>
          <b-nav-item :active="$route.path===item.to" :to="item.to" v-for="item in menus" :key="item.name">{{item.name}}</b-nav-item>
        </b-nav>
        <!-- <p v-for="d in data" :key="d.id">{{d.created_at}}</p> -->
        <router-view :patients="data"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {db} from '../../db'
export default {
  data() {
    return {
      menus: [
        { name: 'Patient', to: '/statistic' },
        { name: 'Discovery', to: '/discovery' },
        { name: 'Hotel', to: '/hotel' },
        { name: 'Address', to: '/address' },
        { name: 'Demographic', to: '/demographic' },
        { name: 'Register', to: '/register' },
        { name: 'Time', to: '/time' },
      ],
      data: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {

      this.data = []

      let patientRef = await db
        .collection("Patient")
        // .where('rejected_at', '==', null) //TODO use this later
        .limit(10000)
        .get();

      for(let p of patientRef.docs) {
        this.data.push({
          id: p.id,
          ...p.data()
        })
      }

    }
  }
}
</script>
