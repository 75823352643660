<template>
  <div>
    <div class="d-flex mt-2 mb-2">
      <h5 class="text-primary">Income</h5>
      <button 
        class="btn btn-primary ml-4" 
        @click="$bvModal.show('income-setting')">
          <i class="fas fa-cog"></i>
      </button>
    </div>
    <Bar 
      :chart-options="chartOptions" 
      :chart-data="chartData" 
      :dataset-id-key="'label'"
      :chart-id="'daily-income-chart'" 
      :width="800" 
      :height="400" />

    <modal-graph-color-setting 
      @change="saveColor"
      v-model="colorList" 
      :id="'income-setting'" 
      :legends="legendList"/>
  </div>
</template>

<script>
import GraphColorSettingMixins from '../../mixins/graph-color-setting'
import ModalGraphColorSetting from '../../components/Modal/GraphColorSetting.vue'
import {
  Bar
} from 'vue-chartjs/legacy'
export default {
  props: ['payments', 'period'],
  mixins: [GraphColorSettingMixins],
  components: {
    Bar,
    ModalGraphColorSetting
  },
  data() {
    return {
      GRAPH_ID: 'IncomeGraph',
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        plugins: {
          datalabels: {
            color: '#000',
            anchor: 'end',
            align: 'end',
            formatter: value => {
              return value.toLocaleString();
            }
          },
          legend: {
            display: false // Set this to false to hide the legend
          },
        },
        layout: {
          padding: {
            right: 100, // กำหนดช่องว่างด้านบนของกราฟ (หน่วย pixel)
          },
        }
      },
    }
  },
  watch:{
    payments: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.label))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'label'))
      },
      immediate: true
    },
    colorSetting: {
      handler() {
        this.setLegendList(this.preparedData.map( item => item.label))
        this.setColorList(this.generateBackgroundColor(this.preparedData, 'label'))
      },
      immediate: true
    },
  },
  computed: {
    preparedData() {
      let data = [
        {
          label: 'Cash',
          amount: 0,
          index: 0
        },
        {
          label: 'Credit Card',
          amount: 0,
          index: 1
        },
        {
          label: 'Insurance',
          amount: 0,
          index: 2
        },
        {
          label: 'Internet Banking',
          amount: 0,
          index: 3
        },
        {
          label: 'Others',
          amount: 0,
          index: 4
        },
        {
          label: 'Total Income',
          amount: 0,
          index: 5
        },
      ]

      for(let item of this.payments){
        data[0].amount += item.cash
        data[1].amount += item.credit
        data[2].amount += item.insurance
        data[3].amount += item.internet_banking
        data[4].amount += item.other
        data[5].amount += (item.cash+ item.credit+ item.insurance+item.other+ item.internet_banking)
      }

      return data
    },
    chartData(){

      let chartData = {
        labels: this.preparedData.map(item => item.label),
        datasets: [{
          label: 'income',
          data: this.preparedData.map(d => d.amount),
          backgroundColor: this.colorList
        }]
      }

      return chartData
    }
  }
}
</script>